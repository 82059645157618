import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
