import * as tslib_1 from "tslib";
import { IndexDBService } from './index_db.service';
import { LocalStorageService } from './local-storage.service';
import { ExpirationTime } from '../providers/expiration-time';
import { ClubFibexFeedingService } from './club-fibex-feeding.service';
import * as i0 from "@angular/core";
import * as i1 from "./index_db.service";
import * as i2 from "./local-storage.service";
import * as i3 from "./club-fibex-feeding.service";
export class CategoryService {
    constructor(indexDB, localStorage, clubFibexAsync) {
        this.indexDB = indexDB;
        this.localStorage = localStorage;
        this.clubFibexAsync = clubFibexAsync;
        this.categories = [];
    }
    getCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // Verificar si los datos existen en IndexedDB
                const cachedCategories = yield this.indexDB.getTable('categories');
                const dataTable = yield cachedCategories.findAll();
                if (dataTable.length > 0) {
                    // Comprobar si el token de expiración está vencido
                    const cacheStatus = this.localStorage.getWithExpiry('categories');
                    console.log(cacheStatus);
                    if (!cacheStatus || cacheStatus.status === false) {
                        // Token de expiración vencido, obtener nuevos datos de la base de datos remota y actualizar IndexedDB
                        const newCategories = yield this.fetchCategoriesFromServer();
                        yield cachedCategories.delete();
                        yield cachedCategories.insert(newCategories); // Actualiza con el tiempo de expiración deseado
                        // Actualizar el valor en localStorage
                        this.localStorage.setWithExpiry('categories', 'categories', ExpirationTime.TwentyFourHours);
                        return newCategories;
                    }
                    else {
                        // Token de expiración no vencido, devolver los datos de IndexedDB
                        return dataTable;
                    }
                }
                else {
                    // No hay datos en IndexedDB, obtener nuevos datos de la base de datos remota y guardar en IndexedDB
                    const newCategories = yield this.fetchCategoriesFromServer();
                    yield cachedCategories.insert(newCategories); // Actualiza con el tiempo de expiración deseado
                    // Actualizar el valor en localStorage
                    this.localStorage.setWithExpiry('categories', 'categories', ExpirationTime.TwentyFourHours);
                    return newCategories;
                }
            }
            catch (error) {
                console.error('Error obteniendo categorías:', error);
                return [];
            }
        });
    }
    fetchCategoriesFromServer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const categories = yield this.clubFibexAsync.GetCategories();
                console.log(categories);
                return categories;
            }
            catch (error) {
                return [];
            }
        });
    }
}
CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.IndexDBService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.ClubFibexFeedingService)); }, token: CategoryService, providedIn: "root" });
