import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from '../services/local-storage.service';
import axios from 'axios';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ConsultasService } from '../services/consultas.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _localStorage: LocalStorageService, private _consult: ConsultasService) {
    
    // Implementar interceptor axios
    // const instance = axios.create();
    axios.interceptors.request.use((config) => {
      try {

        const token = this._localStorage.get("auth");
        

        
        
        const origin = new URL(config.url).origin;
        const originTls = new URL(environment.URLApi).origin;
  
        if(token && !config.headers.auth && origin === originTls) {
          config.headers.auth = token;
        }
      }
      catch(err) {
        console.error(err);
       }

      return config;
    });

    axios.interceptors.response.use((res) => {
      // console.log(">>>>", res);
        if(res.status == 403) {
            this.unauthorized();
        }

        return res;
    }, (err) => {
        if(axios.isAxiosError(err)) {
            if(err.response.status == 403) {
                this.unauthorized();
            }
        }
    })
  }
    
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    try {
      // Obtén el token de alguna manera
      const authToken = this._localStorage.get("auth");

      const origin = new URL(req.url).origin;
      const originTls = new URL(environment.URLApi).origin;

      if(!req.headers.get("auth") && origin === originTls) {
          // Clona la petición y reemplaza el encabezado original con
          // el encabezado de autorización que incluye el token
          const authReq = req.clone({
            headers: authToken ? req.headers.set('auth', authToken) : req.headers
          });
          
          // Envia la petición clonada al siguiente manejador.
          return next.handle(authReq).pipe(
              catchError((error: HttpErrorResponse) => {
                  if (error.status === 403) {
                      this.unauthorized();
                    }
                    // Reenvía el error para que otros manejadores de errores también puedan procesarlo
                    return throwError(error);
              })
          )
      }
    }
    catch(err) { }

    return next.handle(req);
  }


  unauthorized() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    console.log("Logout");
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    this._consult.Logout();
  }
}