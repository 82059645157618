import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { RoutingAppService } from '../../../services/routing-app.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ModalService } from '../../../services/modal.service';
export class ModalRegisterCodeComponent {
    constructor(
    //Ionic Services
    popOver, alertCtrl, 
    //Services app
    navigationServ, localStorage, modalServices) {
        this.popOver = popOver;
        this.alertCtrl = alertCtrl;
        this.navigationServ = navigationServ;
        this.localStorage = localStorage;
        this.modalServices = modalServices;
        this.cancelTextBtn = 'Cancelar';
        this.successTextBtn = 'Aceptar';
        this.title = 'Raspa y gana';
        this.formPlaceholder = 'Ingrese el código aqui';
        this.formType = 'text';
        this.message = 'Introduce el código ubicado en la parte posterior de la tarjeta de regalo';
        this.modalImage = '';
        this.canShowModal = true;
        this.formValue = '';
    }
    ngOnInit() {
    }
    dismiss() {
        // this.canShowModal = false;
        this.popOver.dismiss();
    }
    successButtonClick(numberSucces) {
        switch (numberSucces) {
            case 0:
                // this.setStorageElement();
                const buttons = [
                    {
                        titleButton: 'Cerrar',
                        onClick: () => this.dismiss()
                    },
                    // {
                    //     titleButton: 'Ver Opciones',
                    //   onClick: () => this.redirectToScratch()
                    // },
                    {
                        titleButton: 'Explorar promociones',
                        onClick: () => {
                            this.navigationServ.ChangeRuta('/club-fibex/home?qPricing=0--1000&qDiscountType=discount&qCities=&qCompanies=&qfilterCitiesByComp=false&qTypeSearch=0&qSracthWin=1&fromFilter=true');
                            this.dismiss();
                            // this.localStorage.set('saw','100');
                        }
                    }
                ];
                this.modalServices.generateModalSuccess("¡Felicidades!", "Ha canjeado correctameente su tarjeta de regalo", "Volver al inicio", buttons);
                this.dismiss();
                break;
            case 1:
                this.modalServices.generateModalError("¡Error!", "El codigo de regalo es incorrecto por favor intente nuevamente", "Aceptar", [{
                        titleButton: 'Cerrar',
                        onClick: () => this.dismiss()
                    },]);
                break;
            case 2:
                this.modalServices.generateModalError("¡Error!", "El codigo de regalo ya ha sido usado por favor intente con otro", "Aceptar", [{
                        titleButton: 'Cerrar',
                        onClick: () => this.dismiss()
                    },]);
                break;
            default:
                break;
        }
    }
    presentAlert(title, message, buttons, timeout) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alertButtons = buttons.map(button => ({ text: button.titleButton, handler: button.onClick }));
            const alert = yield this.alertCtrl.create({
                header: title,
                message: message,
                // mode: 'ios',
                buttons: alertButtons
            });
            yield alert.present();
            if (timeout) {
                setTimeout(() => {
                    alert.dismiss();
                }, timeout);
            }
        });
    }
    redirectToScratch() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.setStorageElement();
            this.dismiss();
            this.navigationServ.ChangeRuta('club-fibex/scratch-and-win');
        });
    }
    setStorageElement() {
        const storageItemName = "saw";
        this.localStorage.set(storageItemName, {
            mount: "100$"
        });
    }
}
