export const environment = {
  production: true, // set TRUE before you build and release a prod version.
  // Set your app configurations here.
  // For the list of config options, please refer to https://ionicframework.com/docs/api/config/Config/
  version: "V1.0.5",
  /*  BACKENDFULL      */
  UrlFull: "https://backend.thomas-talk.me/", // https://backend.thomas-talk.me/
  /*  APICLUBFIBEX      */ 
  apiTest: 'https://apiclub.fibextelecom.info', //'https://apiclub.fibextelecom.info' 'http://localhost:9001' //https://apitest1.thomas-talk.me
  apiTestWS: 'https://apiclub.fibextelecom.info', // https://apiclub.fibextelecom.info

  clubFibexBD: "thomas_clubfibex",
  bd:"thomas_clubfibex",// thomas_clubfibex thomas_sandbox_clubfibex
  apiQR: "https://qr.fibextelecom.info",  //"https://apites4.thomas-talk.me",  // "https://qr.fibextelecom.info",

  //Club fibex
  favMethod: "FavApp",
  qrMethod: "GeneratedQR",
  //GiftCard
  endpointExchange: "gift-card/exchange",
  endpointConsult: "gift-card/consult",
  endpointHistory: "gift-card/history",
  //* API PHP */
  apiPhP: "https://login.thomas-talk.me/api/?",
  apiResetPassword: "https://login.thomas-talk.me/api/pw/?",
  apiVerifyCedula: "https://login.thomas-talk.me/api/v/?",

  NameBDV2:'thomas_colas',
  kioskoApp: false,
  ExternalFibexPlay: true,
  botIAURL: "https://apitest3.thomas-talk.me", //'http://localhost:3000',
  Lic: "584120202020@c.us",
  key: "T0rNaDoK4tr1Na?RTgcNmhKU=",
  UrlSubscriptions: "wss://pha.thomas-talk.me/subscriptions",
  URLApi: 'https://apitls.fibextelecom.net/',//, 'https://apitls.fibextelecom.info/,//'https://apitls.fibextelecom.info/',//'https://apitls.fibextelecom.info/', //'https://apitest4.thomas-talk.me/',//'https://apitls.fibextelecom.net/',//, 'https://apitls.fibextelecom.info/'

  Token: "s1e1/c1r2e-2t.2a3r*3i4a",
  TokenUpdate: "4p1_[51t3m4s]_f186@th0m4s",
  UserBackendFull: "Onur",
  PasswordBackendFull: "L4V1d43NsuPl3N1tud**=BghYjLaQeTB",
  TokenBackendFull:
    "h34BEVSzAijWRFeXBmRGA8FfarVHVLHZo_mfmS7c7TFUjlpMt0LFiI-NKy1tr89pJwpXoPiL2BemcwuDKIuJzeqii-eiZXilqyQYOcm_o1_SE29K5J76E1G-ZY--m0QHdSXvLrR2-dK6I8S7x24pbyzixoSgldSYCulhmooI5Ls0jAr4HS6a",
  referenciaRecomiendaGana: "https://sofia.fibextelecom.net?abonado=",
  //Token Encript
  urlUploadService: "https://img.thomas-talk.me/api/",
  authdbFUll: "Basic " + btoa("Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB"),

  ApiBackendThomas: "https://dbfull.thomas-talk.me/api/", // https://dbfull.thomas-talk.me/api/

  NameBD: "thomas_cobertura",


  tableNotify: "tm_notifications_clients",



  //! ChatBot
  botAPI: "https://bot.thomas-talk.me/bot/tell-me",



  //! hash info
  hash_url: "https://apiext.thomas-talk.me/",
  hash_token:
    "SpOgMuwsvIpT4zjn-c1FcRPOf7_fdUbPmNXs4ktmUfjV_gW8rFJpvR71eR6Bojv7gROJrZ79NU0pVKbcL3IMhoQID-NFvbuxVSfZbsrCBz8f_dVLtlY_V0Dufug7UMAYNX83xLMIU9D4a7mfyLYBTNmUn5dXVYWdrVnQkNbZwhkm102yRMkH",
  hash_user: "Chechelev",
  hash_password: "Jky/6#()[}NgEasGHtIr=456sdw5HHd",

  platform: "App",
  config: {
    autoFocusAssist: false,
    menuType: "overlay",
  },
  // Set language to use.
  language: "en",
  // Loading Configuration.
  // Please refer to the official Loading documentation here: https://ionicframework.com/docs/api/components/loading/LoadingController/
  loading: {
    spinner: "circles",
  },
  // Toast Configuration.
  // Please refer to the official Toast documentation here: https://ionicframework.com/docs/api/components/toast/ToastController/
  toast: {
    position: "bottom", // Position of Toast, top, middle, or bottom.
  },
  toastDuration: 3000, // Duration (in milliseconds) of how long toast messages should show before they are hidden.
  // Angular Google Maps Styles Config
  
  // //
};
