import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';

@Component({
  selector: 'app-list1',
  templateUrl: './list1.component.html',
  styleUrls: ['./list1.component.scss'],
})
export class List1Component implements OnInit {
  @Input() item: ProductInterface;
  @Input() category: string = 'blue-new';
  @Input() validateProductPrice: boolean = false;
  @Input() currentTabValue!: string;
  constructor(
    private router: Router,
    public clubService: ClubFibexFeedingService
  ) {
  }

  ngOnInit() {
  }

  goToRestDetail(event) {
    const target = event.target as Element;
    
    this.router.navigate(['club-fibex/product-details', this.item.id, ((target.id).includes('btn_show_qr'))])
  }

  public subtractToNumber(num1: unknown, num2: unknown): number {
    return Number(num1) - Number(num2);
  }
  public isPriceGreaterThanZero(price: string):boolean {
    return Boolean(Number.parseFloat(price));
  }

  public showDecimalOfNum = (number:number):string => {
    try {
      const i = String(number).indexOf('.')
      return (i  && i !== -1) ? String(number).slice(0,i) : String(number)
    } catch (error) {
      console.error(error)
    }
  }
  public substractNumbers(number1: unknown, number2: unknown): number{
    return Number(number1)-Number(number2);
  }

}
