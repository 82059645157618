/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal2.component";
var styles_Modal2Component = [i0.styles];
var RenderType_Modal2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Modal2Component, data: {} });
export { RenderType_Modal2Component as RenderType_Modal2Component };
export function View_Modal2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" modal2 works!\n"]))], null, null); }
export function View_Modal2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal2", [], null, null, null, View_Modal2Component_0, RenderType_Modal2Component)), i1.ɵdid(1, 114688, null, 0, i2.Modal2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Modal2ComponentNgFactory = i1.ɵccf("app-modal2", i2.Modal2Component, View_Modal2Component_Host_0, {}, {}, []);
export { Modal2ComponentNgFactory as Modal2ComponentNgFactory };
