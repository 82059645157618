
interface IWebsocketAPI {
    /**
     * Estado de la conexión
     */
    readonly state: WebSocket["readyState"];
}

export class WebsocketAPI implements IWebsocketAPI {
    /** 
     * Instancia real del websocket 
     * */
    private socket: WebSocket | null = null;

    /**
     * Estado actual de la conexión
     */
    public get state(): WebSocket["readyState"] {
        return this.socket.readyState;
    }
    
    /**
     * Permite crear una nueva conexión websocket hacia algún servidor que se comunique mediante una estructura en específica
     * @param path ruta de la conexión
     * @param protocols protocolo de conexión
     */
    constructor(path: string, protocols?: string | string[]) {
        this.socket = new WebSocket(path, protocols);
        
        // Mensajes entrantes
        this.socket.onmessage = (ev) => {
            if(this.onmessage) {
                try {
                    const message = JSON.parse(ev.data);

                    if( "event" in message && "data"  in message ) {
                        try {
                            this.onmessage(message.event, message.data);
                        }
                        catch(err) {
                            console.log(err);
                        }
                    }
                    else throw new Error("El dato recibido no cumple con la estructura mínima");
                }
                catch(err) {
                    console.error(err);
                    throw new Error()
                }
            }
        }

        // Error conexión
        this.socket.onerror = (ev) => {
            if(this.onerror) this.onerror(ev);
        }

        // Conexión establecida
        this.socket.onopen = (ev) => {
            if(this.onopen) this.onopen(ev);
        }

        // Conexión cerrada
        this.socket.onclose = (ev) => {
            if(this.onclose) this.onclose(ev);
        }
    }

    /**
     * Permite enviar algún dato al servidor (Puede ser un objeto, Array, String, Number, Boolean... en resumen cualquier entidad JSON válido)
     * @param event Nombre evento (en caso de no ser necesario, puede ser null)
     * @param data Datos asociado
     */
    send(event: string | null, data: unknown) {
        if(this.socket) {
            // Enviar mensaje
            this.socket.send(JSON.stringify({
                event: event,
                data: data
            }));
        }
    }

    close(code?: number, reason?: string) {
        if(this.socket) {
            this.socket.close(code, reason)
        }
        else throw new Error("El socket ya se encontraba cerrado");
    }

    /**
     * Se dispara este evento cuando el servidor notifica algún cambio
     * @param event nombre del evento
     * @param data data asociada entrante
     */
    onmessage?(event: string, data: any): void;

    /**
     * Se dispara cuando existe algun error en la conexión
     * @param ev Evento asociado
     */
    onerror?(ev: Event): void;

    /**
     * Se dispara cuando se logró establecer la conexión
     * @param ev evento asociado
     */
    onopen?(ev: Event): void;

    /**
     * Se dispara cuando se cierra la conexión
     * @param ev evento asociado
     */
    onclose?(ev: Event): void;

    // ********************************************** CONSTANTES ESTÁTICAS **************************************
    
    /**
     * State conectando
     */
    public static readonly CONNECTING: 0;
    /**
     * State conectado
     */
    public static readonly OPEN: 1;
    /**
     * State cerrando conexión
     */
    public static readonly CLOSING: 2;
    /**
     * State conexión cerrada
     */
    public static readonly CLOSED: 3;
}