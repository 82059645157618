import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController,Platform } from '@ionic/angular';
@Component({
    selector: 'app-gallery-photos',
    templateUrl: './GalleryPhotos.component.html',
    styleUrls: ['./GalleryPhotos.component.scss']
})
export class GalleryPhotosComponent implements OnInit,AfterViewInit,OnDestroy { 

    @Input() images: {value: number,url: string}[]  = [];
    @Input() currentImage?: {value: number,url: string}

    @ViewChild('slideImages',{static: false}) slideImage: IonSlides;

    public imagesPresent: {value: number,url: string}[] = []
    public isDesktop: boolean = false;
    public isZoomed: boolean = false;
    constructor(
        private modalControler: ModalController,
        public platform: Platform,
    ){

    }
    ionViewDidEnter(){
      this.isDesktop = (this.platform.is('desktop'))
    //   if(this.isDesktop) this.disableDoubleClick();
      this.slideImage.update();
    //   if(this.isDesktop){
        // this.sliderOptions.zoom.toggle = !this.isDesktop; // Desactiva el zoom con doble clic o doble toque
        // }
       
      
    }
    public loadedReferImg: boolean = false;
    public page: number = 1;
    sliderOptions: any = { slidesPerView: 1, 
        loop: true,
        pagination:true,
    //     zoom: {
    //       maxRatio: 3,  // Límite máximo del zoom in
    //       minRatio: 1.5, // Límite mínimo del zoom out
        
    // },
         // allowTouchMove: false,
        touchEventsTarget: 'container', 
        passiveListeners: false,    
        on: {
            zoomChange: (scale: number) => {
              this.handleZoomChange(scale);
            }
          }
    }
    ngOnInit(): void {
     
    }
    ngAfterViewInit(): void {
        this.imagesPresent = this.images;
        this.loadedReferImg = true;
        this.page = this.currentImage.value;
      if(this.currentImage.value != 1)  this.slideTo(this.currentImage.value);
        // this.slideImage.update();
    }
    toggleZoom(event: any) {
        
        const img = event.target;
        if (img.classList.contains('zoomed')) {
          img.classList.remove('zoomed');
        } else {
          img.classList.add('zoomed');
        }
      }
    onBackNavigation(){
        this.modalControler.dismiss();
    }
    slideChanged = (ev?: any) => {
        this.slideImage.getSwiper().then(swiper => {
            this.isZoomed = false;
            if (swiper.zoom.scale !== 1) {
              swiper.allowSlideNext = false;
              swiper.allowSlidePrev = false;
            } else {
              swiper.allowSlideNext = true;
              swiper.allowSlidePrev = true;
            }
          });
         this.slideImage.getActiveIndex().then(resp=>{
          
            if(resp>this.images.length) this.page = 1;
            else if(resp<1) this.page = this.images.length;
            else this.page = resp;
        });
  
    }
    slideTo(index: number){
        this.slideImage.slideTo(index)
    }
    public handleSliderNav = (elemScroll: any, direction: 'left' | 'right'): void => {
        // const element = elemScroll.querySelector(".product_slider") as HTMLDivElement | null;
       direction == 'right' ? this.slideImage.slideNext() :  
       this.slideChanged(); 
       this.slideImage.slidePrev();
        // if(element) {
        //   element.scrollBy({left: direction === 'right' ? 272 : -272, behavior:'smooth'})
        // }
    }
    async disableDoubleClick() {
        const swiper = await this.slideImage.getSwiper();
        const images = document.querySelectorAll('.swiper-slide img');
        images.forEach((img) => {
          img.addEventListener('dblclick', (event) => {
            event.preventDefault(); // Previene el comportamiento predeterminado del doble clic
            swiper.zoom.out(); // Resetea el zoom en caso de doble clic
          });
        });
      }
    
      // Método para hacer zoom in
      async zoomIn() {
        // if(this.isDesktop){
        const swiper = await this.slideImage.getSwiper();
        const currentZoom = swiper.zoom.scale;
        if (currentZoom < this.sliderOptions.zoom.maxRatio) {
          swiper.zoom.in(); // Acercar el zoom
        }
        this.checkSlideMovable(swiper);
    // }
      }
    
      // Método para hacer zoom out
      async zoomOut() {
        // if(this.isDesktop){
        const swiper = await this.slideImage.getSwiper();
        const currentZoom = swiper.zoom.scale;
        if (currentZoom >=  this.sliderOptions.zoom.maxRatio) {
          swiper.zoom.out(); // Alejar el zoom
        }
        this.checkSlideMovable(swiper);
    // }
      }
    
      // Controla si el slider puede moverse al siguiente o anterior slide según el zoom
      async checkSlideMovable(swiper: any) {
        if (swiper.zoom.scale > 1) {
          swiper.allowSlideNext = false;
          swiper.allowSlidePrev = false;
          this.isZoomed = true;
        } else {
          swiper.allowSlideNext = true;
          swiper.allowSlidePrev = true;
          this.isZoomed = false;
        }
      }
      async handleZoomChange(scale: number) {
        const swiper = await this.slideImage.getSwiper();
        
        // Si el zoom es menor que el permitido, restablecer
        if (scale < this.sliderOptions.zoom.minRatio) {
          swiper.zoom.set(this.sliderOptions.zoom.minRatio);
        }
        
        this.isZoomed = scale > 1; // Actualiza el estado del zoom
      }
    
    
      ngOnDestroy(): void {
        this.imagesPresent = [];
        this.images = [];
    }
}
