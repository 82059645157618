import { NavController } from '@ionic/angular';
import { EstadisticasService } from './estadisticas.service';
import { NavigationEnd, NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./estadisticas.service";
import * as i3 from "@angular/router";
export class RoutingAppService {
    constructor(navCtrl, _estadistica, _router, _act_Route) {
        this.navCtrl = navCtrl;
        this._estadistica = _estadistica;
        this._router = _router;
        this._act_Route = _act_Route;
        this.showSpinnerSubject = new BehaviorSubject(false);
        //* to set showSpinnerSubject true if the routing is loading | or false if routing is loaded
        this.subRouterEvents = () => {
            this.routerSub = this._router.events.subscribe((routerEvent) => {
                if (routerEvent instanceof NavigationStart) {
                    this.showSpinnerSubject.next(true);
                }
                if (routerEvent instanceof NavigationEnd) {
                    this.showSpinnerSubject.next(false);
                }
            });
        };
        //* get the value of showSpinnerSubject
        this.getShowSpinnerSubject = () => {
            return this.showSpinnerSubject.asObservable();
        };
    }
    ChangeRuta(Ruta) {
        if (Ruta === 'new-login' || Ruta === '/home')
            this.navCtrl.navigateRoot([Ruta], { replaceUrl: true });
        this.navCtrl.navigateForward(Ruta);
        this._estadistica.RegisterAccion(Ruta);
        this._estadistica.RutaActual = Ruta;
    }
    setShowSpinnerValue(value) {
        this.showSpinnerSubject.next(value);
    }
    loadRouterParamsCat() {
        let actions = {};
        const paramSub = this._act_Route.queryParamMap.subscribe((data) => {
            const { params } = data;
            if (params.qCategory) {
                actions.qCategory = params.qCategory.split('--');
            }
            if (params.qPricing) {
                actions.qPricing = params.qPricing.split('--');
            }
            if (params.qDiscountType) {
                actions.qDiscountType = params.qDiscountType;
            }
            if (params.qCities) {
                actions.qCities = params.qCities.split('--');
            }
            if (params.qCompanies) {
                actions.qCompanies = params.qCompanies.split('--');
            }
            if (params.qtypePromoo) {
                actions.qtypePromoo = params.qtypePromoo;
            }
            if (params.qTypeSearch) {
                actions.qTypeSearch = params.qTypeSearch;
            }
            if (params.qSracthWin) {
                actions.qSracthWin = Number(params.qSracthWin);
            }
        });
        return actions;
    }
    isFilteringApplied(actions) {
        // Valores por defecto
        const defaultValues = {
            qDiscountType: 'discount',
            qPricing: ['0', '1000'],
            qSracthWin: 0,
            qTypeSearch: '0'
        };
        // Verificar si se está aplicando algún filtro diferente a los valores por defecto
        if (actions.qCategory ||
            actions.qCities ||
            actions.qCompanies ||
            (actions.qDiscountType && actions.qDiscountType !== defaultValues.qDiscountType) ||
            (actions.qPricing && JSON.stringify(actions.qPricing) !== JSON.stringify(defaultValues.qPricing)) ||
            (actions.qSracthWin && actions.qSracthWin !== defaultValues.qSracthWin) ||
            (actions.qTypeSearch && actions.qTypeSearch !== defaultValues.qTypeSearch)) {
            return true;
        }
        // Verificar si no se aplica ningún filtro (todos los valores están vacíos)
        if (!actions.qCategory && !actions.qPricing && !actions.qDiscountType && !actions.qCities && !actions.qCompanies && !actions.qTypeSearch && !actions.qSracthWin) {
            return false;
        }
        return false;
    }
}
RoutingAppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoutingAppService_Factory() { return new RoutingAppService(i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i2.EstadisticasService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i3.ActivatedRoute)); }, token: RoutingAppService, providedIn: "root" });
