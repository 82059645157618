import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";

// abonado
// nombre promocion
// precio
// fecha hora

interface IRecipe {
  abonado: string;
  descuento: number; // descontar
  displayDescuento: string; // vistaprevia del descuento
  fechaFin: string; // fecha fin
  fechaInicio: string; // fecha inicio
  monto: number; // monto total (sin descuento)
  nombre: string; /// nombre promo
  descripcion: string;
  tipopromo:number;
}

@Component({
  selector: "app-modal-success",
  templateUrl: "./modal-success.component.html",
  styleUrls: ["./modal-success.component.scss"],
})
export class ModalSuccessComponent implements OnChanges {
  @Input() data: IRecipe[] | IRecipe | null = null;
  @Output() finished: EventEmitter<void> = new EventEmitter();

  private oldData: IRecipe[] | IRecipe | null = null;

  public abonado: string = "Desconocido";
  public nombre_promo: string = "Desconocido";
  public descripcion: string = "Desconocido";
  public precio: number = 0;
  public precioneto: string = "";
  public descuento: number = 0;
  public displayDescuento?: string;
  public fecha: string = new Date().toLocaleString();

  public tableRecipe: {
    productName: string;
    amount: number;
    discount: string;
  }[] = [];

  ngOnInit() {
    this.analizeData();
  }

  ngOnChanges() {
    this.analizeData();
  }

  analizeData() {
    if (this.data !== this.oldData) {
      this.oldData = this.data;
      if (this.data) {
        if (this.data instanceof Array) {
          const dataBase = this.data[0];

          if (dataBase) {
            if (this.data.length > 1) {
              try {
                this.abonado = dataBase.abonado;
                this.nombre_promo = dataBase.nombre;
                this.descripcion = dataBase.descripcion;
                this.precio = 0;
                this.descuento = 0;
                this.displayDescuento = "";
                this.fecha = dataBase.fechaInicio;
  
                this.tableRecipe = this.data.map((dataItem) => {
                  if(dataItem.tipopromo === 0){
                    //PRECIO
                    this.precio = dataItem.monto - dataItem.descuento < 0 ? 0 : (dataItem.monto - dataItem.descuento);
                  }else{
                    //PORCENTAJE
                    let descuento = (dataItem.descuento / 100 ) * dataItem.monto;
                    this.precio = dataItem.monto - descuento  < 0 ? 0 : (dataItem.monto - descuento);
                  }
    
                  return {
                    productName: dataItem.nombre,
                    amount: dataItem.monto,
                    discount: dataItem.displayDescuento,
                  };
                }); 
              } catch (error) {
                console.error(error)
              }
            } else {
              this.data = dataBase;
            }
          }
        }else{
          if (this.data && !(this.data instanceof Array)) {
            try {
              if(this.data.tipopromo === 0){
                //PRECIO
                this.precio = this.data.monto - this.data.descuento < 0 ? 0 : (this.data.monto - this.data.descuento);
              }else{
                //PORCENTAJE
                let descuento = (this.data.descuento / 100 ) * this.data.monto;
                this.precio = this.data.monto - descuento  < 0 ? 0 : (this.data.monto - descuento);
              }
              this.precioneto = this.fixNumber(this.data.monto)
              this.abonado = this.data.abonado;
              this.nombre_promo = this.data.nombre;
              this.descripcion = this.data.descripcion;
              this.descuento = this.data.descuento;
              this.displayDescuento = this.data.displayDescuento;
            } catch (error) {
              console.error(error);
            }
            //this.fecha = this.data.displayDescuento;
          }
        }

        
      }
    }
  }

  fixNumber(digit: number) {
    const money = (digit * 100).toString().padEnd(3, "0");
    return (
      money.slice(0, money.length - 2) +
      "." +
      money.slice(money.length - 2, money.length)
    );
  }
}
{{  }}