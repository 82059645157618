import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../services/local-storage.service';
import { RoutingAppService } from '../../services/routing-app.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {

  constructor(
    private router: Router,
    private _localStorageService: LocalStorageService,
    private _routing:RoutingAppService
  ){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean > | Promise<boolean> | boolean  {
      const user = this._localStorageService.get('auth-user');
      if (!user) {
        this._routing.ChangeRuta('/');
        return false;
      }
      return true;
  }  
}
