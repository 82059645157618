import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonSelect, NavController } from '@ionic/angular';
import { CouponProductInterface } from '../../../../interfaces/product.interface';
import { HelperService } from '../../../../services/helper.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { UserAuthenticationService } from '../../../../services/user-authentication.service';
export class List4Component {
    constructor(router, helper, clubService, alertCtrl, navCtrl, athentication) {
        this.router = router;
        this.helper = helper;
        this.clubService = clubService;
        this.alertCtrl = alertCtrl;
        this.navCtrl = navCtrl;
        this.athentication = athentication;
        this.tabID = 'Used';
        //  @Input() coupons: CouponProductInterface[] = [];
        this.cuponCanjeado = new EventEmitter();
        this.onClickEmit = new EventEmitter();
        this.showCouponSelector = false;
        this.totalCoupons = [];
        this.showModal = false;
        this.totalMissingDays = 0;
        this.isPromotionExpired = false;
        this.calculateStatusCupon = (createdAt, fechaLimite, usado) => {
            if (usado === 1)
                return 1;
            const currentDate = new Date();
            const creationDate = new Date(createdAt);
            const expiryDate = new Date(creationDate);
            expiryDate.setDate(expiryDate.getDate() + fechaLimite);
            if (currentDate <= expiryDate) {
                return 0; // Sin usar y dentro del rango
            }
            else {
                return 2; // Expirado
            }
        };
        this.substractNumbersByString = (number1, number2) => {
            return parseFloat(number1) - parseFloat(number2);
        };
        try {
            this.user = this.athentication.getUser();
        }
        catch (error) {
            console.error(error);
        }
    }
    ngOnInit() {
        this.CalcMissingDays();
        //  this.totalCoupons = this.coupons.filter(eItem => eItem.id === this.item.id && eItem.usado === 0)
        this.currentItem = this.item;
    }
    itemAccordion(itemId) {
        if (this.tabID === "withoutUsing") {
            const allInputs = document.querySelectorAll('.check_input_accordion');
            const divContent = document.querySelectorAll('.div_content');
            allInputs.forEach((input, i) => {
                if (input.id != itemId) {
                    input.checked = false;
                    divContent[i].classList.remove('acordion_active');
                }
            });
            const inputCheck = document.getElementById(`${itemId}`);
            const content = document.getElementById(`div_content_${itemId}`);
            if (inputCheck.checked)
                content.classList.add('acordion_active'); //Abre el contenido del cupon seleccionado
            else
                content.classList.remove('acordion_active');
        }
    }
    goToRestDetail(id, showCoupon = false) {
        // this.currentItem = this.totalCoupons[0]
        // this.showModal = true;
        this.router.navigate(['club-fibex/product-details', id, showCoupon]);
    }
    isContentActive(itemId) {
        const inputCheck = document.getElementById(`${itemId}`);
        return (inputCheck && inputCheck.checked ? inputCheck && inputCheck.checked : false);
    }
    itemSelected() {
        // this.currentItem = this.totalCoupons[+e.target.value]
        if (this.totalCoupons && this.totalCoupons.length > 1)
            this.handleShowCouponSelector(true);
        else {
            this.showModal = true;
            this.selectCoupon(this.item);
        }
    }
    handleShowCouponSelector(value) {
        if (value === true && this.totalCoupons.length > 1)
            this.showCouponSelector = value;
        else {
            this.showCouponSelector = false;
        }
    }
    openSelector() {
        this.selector.open();
    }
    CalcMissingDays() {
        const fechaFinCupon = new Date(this.item.createdAt);
        fechaFinCupon.setDate(fechaFinCupon.getDate() + this.item.FechaLimite);
        let FechaFinComparar = fechaFinCupon.getTime();
        let FechaActual = new Date().getTime();
        let diff = FechaFinComparar - FechaActual;
        this.totalMissingDays = Math.round(diff / (1000 * 60 * 60 * 24));
        this.isPromotionExpired = FechaActual > FechaFinComparar;
    }
    goToCuponSuccessed() {
        this.showModal = false;
        this.cuponCanjeado.emit(this.currentItem);
    }
    selectCoupon(coupon) {
        if (coupon.TipoCanjeo === 3) {
            this.onClickEmit.emit(this.item);
        }
        else {
            this.currentItem = coupon;
            this.handleShowCouponSelector(false);
            this.showModal = true;
        }
    }
    subtractToNumber(number1, number2) {
        return Number(number1) - Number(number2);
    }
    getPrice(number) {
        return Number(number);
    }
    downloadBill() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { QR, QRurl, price, name, description, idAbonado } = this.currentItem;
            try {
                const result = yield this.clubService.generateQRBill({ NombreProducto: name, description, idAbonado, price, Codigo: QR, Cupon: QRurl });
                if (result) {
                    this.showModal = false;
                    this.alertCtrl.create({
                        header: 'Su cupón ha sido generado con éxito',
                        message: "Puede encontrarlo dentro de la carpeta descargas en su dispositivo",
                        backdropDismiss: false,
                        buttons: [
                            {
                                text: 'Aceptar',
                                role: 'cancel',
                                cssClass: 'secondary cust',
                            }
                        ]
                    }).then(res => res.present());
                    // await this.downloadBase64(result.operation[1].pdf64)
                    // await this.downloadPDF(result.operation.secure_url)
                    this.helper.setIframeToRender({ url: result.operation.secure_url, title: "Cupón generado" });
                    this.navCtrl.navigateForward('/iframe-renderer');
                }
                else {
                    throw new Error();
                }
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    downloadBase64(base64) {
        return new Promise((resolve, reject) => {
            try {
                const link_source = `data:application/pdf;base64,${base64}`;
                const download_link = document.createElement("a");
                const filename = "cupon.pdf";
                download_link.href = link_source;
                download_link.download = filename;
                download_link.click();
                resolve(true);
            }
            catch (error) {
                reject(false);
            }
        });
    }
    downloadPDF(pdf) {
        return new Promise((resolve, reject) => {
            try {
                const link_source = pdf;
                const download_link = document.createElement("a");
                const filename = "cupon.pdf";
                download_link.href = link_source;
                download_link.target = '_blank';
                download_link.download = filename;
                document.body.appendChild(download_link);
                download_link.click();
                document.body.removeChild(download_link);
                resolve(true);
            }
            catch (error) {
                reject(false);
            }
        });
    }
    areEqualsValue(price, priceDiscount) {
        let numberPrice = Number(price);
        let numberDiscountPrice = Number(priceDiscount);
        return numberPrice === numberDiscountPrice;
    }
}
