import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ITab } from '../../../interfaces/pages';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Output() tabChangedEmitter: EventEmitter<ITab> = new EventEmitter();
  @Input() tabs: ITab[] = [];
  public activeTab: ITab;

  constructor() { }

  ngOnInit() {
    this.activeTab = this.tabs && this.tabs[0];
  }

  public onTabClick = (selectedTab: ITab) => {
    try {
      this.activeTab = selectedTab
      this.tabChangedEmitter.emit(selectedTab)
    } catch (error) {
      console.error(error);
    }
  }

}
