/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-register-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-register-code.component";
import * as i3 from "@ionic/angular";
import * as i4 from "../../../services/routing-app.service";
import * as i5 from "../../../services/local-storage.service";
import * as i6 from "../../../services/modal.service";
var styles_ModalRegisterCodeComponent = [i0.styles];
var RenderType_ModalRegisterCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalRegisterCodeComponent, data: {} });
export { RenderType_ModalRegisterCodeComponent as RenderType_ModalRegisterCodeComponent };
export function View_ModalRegisterCodeComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ModalRegisterCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-register-code", [], null, null, null, View_ModalRegisterCodeComponent_0, RenderType_ModalRegisterCodeComponent)), i1.ɵdid(1, 114688, null, 0, i2.ModalRegisterCodeComponent, [i3.PopoverController, i3.AlertController, i4.RoutingAppService, i5.LocalStorageService, i6.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalRegisterCodeComponentNgFactory = i1.ɵccf("app-modal-register-code", i2.ModalRegisterCodeComponent, View_ModalRegisterCodeComponent_Host_0, { cancelTextBtn: "cancelTextBtn", successTextBtn: "successTextBtn", title: "title", formPlaceholder: "formPlaceholder", formType: "formType", message: "message", modalImage: "modalImage" }, {}, []);
export { ModalRegisterCodeComponentNgFactory as ModalRegisterCodeComponentNgFactory };
