import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.scss'],
})
export class Card2Component implements OnInit {

  @Input() image: string = '';
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() isFeatured: boolean = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>()

  loaded = false;
  
  constructor() { }

  ngOnInit() {}

  onClickFn() {
    this.onClick.emit()
  }

}
