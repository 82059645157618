import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import {  ProductInterface } from '../../../interfaces/product.interface'
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
import { UserAuthenticationService,IUserSae } from '../../../services/user-authentication.service';
import { ActivatedRoute, Params } from '@angular/router';

enum FILTER_ACTIONS {
    qCategory = '[FILTER] queryCategory',
    qRanking = '[FILTER] queryRanking',
    qPricing = '[FILTER] queryPricing',
    qCompanies = '[FILTER] queryCompanies',
    qCities = '[FILTER] queryCities',
    qTabDiscountType = '[FILTER] queryDiscountType',
    qSracthWin = '[FILTER] querySracthWin'
  }
  enum COUPON_MONEY_TYPE {
    DISCOUNT = 'discount',
    DOLLAR = 'dollar'
  }
  

@Component({
    selector: 'app-results-search', 
    templateUrl: './ResultsSearch.component.html',
    styleUrls: ['./ResultsSearch.component.scss']
})
export class ResultsSearchComponent implements OnInit, OnChanges, OnDestroy{

    @Input() textResult: string = "";
    
    @Output() closeEventEmit: EventEmitter<void> = new EventEmitter<void>()

    public areLoadingProducts: boolean = true;
    public isEnableLoadProducts: boolean = true;
    public productsSearched: ProductInterface[] = [];
    private allProducstSearched : ProductInterface[] = [];
    private subjectSearch: Subject<string> | undefined; 
    public currentLimit: number = 4;
    public currentOffset: number = 1;
    public user: IUserSae | null;
    public qCompanies = [];
    public qCategory = [];
    public qCities = [];
    public qPricing = [];
    qtypePromoo
    qTypeSearch = 0;
    qSracthWin = 0;
  private paramSub: Subscription;
    constructor(
        private clubService: ClubFibexFeedingService,
        private athentication: UserAuthenticationService,
        private route: ActivatedRoute
    ){
        this.user = this.athentication.getUser();
        this.loadRouterParamsCat();
        this.subjectSearch = new Subject<string>();
        this.subjectSearch
        .pipe(
          debounceTime(300), 
        
        )
        .subscribe(resp => {
          try {
          
      
            if (resp) {
              this.currentOffset = 1;
              if (resp.length >= 1) {
                this.areLoadingProducts = true;
                const responseSearchLocal = this.allProducstSearched.filter(product=>product.name.toLowerCase().includes(resp.toLowerCase()))

                if(!responseSearchLocal.length){
                  this.productsSearched = [];
                  this.getProductsBySearching(resp);                
                }else {
              this.productsSearched = responseSearchLocal;
                  this.areLoadingProducts = false;
            }
                //   this.findAllysByName(resp);
              }

      
              
            } else {
            //   this.marks = this.allMarks;
            }
          } catch (error) {
            // console.error(error)
          }
        }); 
    }
    
    ngOnInit(): void {
    }
    @HostListener('document:click', ['$event'])
onClick(event: MouseEvent) {
    const modalElement = document.getElementById('ion-content-search'); 
    if (modalElement && !modalElement.contains(event.target as Node)) {
       this.closeEventEmit.emit();
    }
}
    ngOnChanges(changes: SimpleChanges): void {
       if(changes["textResult"].currentValue && changes["textResult"].currentValue.length>0) {
        const text = changes["textResult"].currentValue;
        this.subjectSearch.next(text);
       }
    }
    public async getProductsBySearching(name: string){    
        let companies ="";
        let categories = "";
        let franquicies = "";
        if(this.qCompanies) companies = this.qCompanies.join(",");
        if(this.qCategory) categories = this.qCategory.join(",");
        if(this.qCities) franquicies = this.qCities.join(",")
        const response: any[] = await this.clubService.getProductsPaginated(franquicies,this.user.id_contrato,this.currentOffset,this.currentLimit,categories,companies,this.qPricing,this.qTypeSearch,name,this.qSracthWin);
        this.productsSearched.push(...response);
        this.allProducstSearched.push(...response);
        this.areLoadingProducts = false;
        this.isEnableLoadProducts = response.length === this.currentLimit;
    }
    loadRouterParamsCat(): FILTER_ACTIONS | void {//!Load category by the route
        // this.isSearchingData = true;
        var action: FILTER_ACTIONS
          this.paramSub = this.route.queryParamMap.subscribe(async (data: Params) => {
            const { params } = data;
            //Save companyID coming from the route
            this.qCompanies = [];
            this.qCities = []
            this.qCategory = []
            if (params.qCategory) {
              this.qCategory = params.qCategory.split('--')
              action = FILTER_ACTIONS.qCategory
            }
      
            if (params.qPricing) {
              this.qPricing = params.qPricing.split('--')
              action = FILTER_ACTIONS.qPricing
            }
      
            if (params.qDiscountType) {
              action = FILTER_ACTIONS.qTabDiscountType;
            }
            if (params.qCities) {
              this.qCities = params.qCities.split('--');
              action = FILTER_ACTIONS.qCities;
            }
            if(params.qCompanies){
              this.qCompanies = params.qCompanies.split('--');
              action = FILTER_ACTIONS.qCompanies;
            }
            if(params.qtypePromoo){
              this.qtypePromoo = params.qtypePromoo;
            }
            if(params.qTypeSearch){
                this.qTypeSearch = params.qTypeSearch;
            }
            if(params.qSracthWin){
            
              
              this.qSracthWin = Number(params.qSracthWin);
              console.log(this.qSracthWin);
          }
          })
    }
    public infiniteScroll = async (event:any) => {
      try {
      const scrollEvent = event.target as HTMLElement;
      if (this.isEnableLoadProducts) {
        this.currentOffset++;
       await this.getProductsBySearching(this.textResult)
      }
      else  event.target.disabled = true; 
      event.target.complete();
      } catch (error) {
        console.error(error);
      }
    
  }
  public async updateProductsData(){

  }
  ngOnDestroy(): void {
    this.subjectSearch.unsubscribe();
    this.allProducstSearched = [];
    this.productsSearched = [];
  }
}
