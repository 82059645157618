import axios, { AxiosResponse } from "axios";
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CompanyInterface, GetCategoriesInteface, ProductInterface, BannerInterface, CouponProductInterface, CouponRating } from '../interfaces/product.interface';
import { FranquiciaInterface, IPopup } from '../interfaces/getClubFibex.interface';
import { LocalStorageService } from './local-storage.service';
import { IUserSae, UserAuthenticationService } from './user-authentication.service';
import { UserInterface } from '../interfaces/userInfo';
import { BehaviorSubject, Observable } from 'rxjs';
import { QrInterface } from '../interfaces/club-qr.interface';
import { LocationDataInterface } from '../interfaces/location-nominatim.interface';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { dbFull, dbFullDataBase } from "../lib/dbfullv2";
import { SeguridadDatos } from "./bscript.service";
import { ProcedureResponse } from '../interfaces/response_Stored';


interface LandingBanner{id:number;imgDesktop:string;imgMobile:string;type:string;category:string;status:number;createdAt:Date;updatedAt:Date}
type ResFav = { detail: string; status: boolean }

@Injectable({
    providedIn: 'root'
})
export class ClubFibexFeedingService {

    private ShowInfoTable: any = ""
    private Excluido: any = ["createdAt", "updatedAt"];
    private user: IUserSae | null;
    public favProducts: BehaviorSubject<ProductInterface[]> = new BehaviorSubject<ProductInterface[]>([]);
    public goToMainMenuSubject: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    public popupIndexCount:number = 0;
    public db: dbFullDataBase = new dbFullDataBase(environment.clubFibexBD);
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private AuthService: UserAuthenticationService,
        private router: Router,
        private enc: SeguridadDatos
    ) {
        this.user = AuthService.getUser()
    }

    ngOnInit(): void {
        //this.ShowInfo();
    }

    async GetCategories(): Promise<GetCategoriesInteface[] | null> {
        try {
            const result: AxiosResponse<GetCategoriesInteface[]> = await axios.get<GetCategoriesInteface[]>(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_EmpCategoria/Status/1`)
            return result.data;
        } catch (error) {
            return null
        }
    }

    async GetCompanies(): Promise<CompanyInterface[]> {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.CompanyApp`;
            const result: CompanyInterface[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null;
        }
    }

    /**
     * Permite obtener la cantidad total de compañias
     */
    async GetCountCompanies(): Promise<number> {
        try {
            const result: number = await this.db.GET_LENGTH({table: `${environment.clubFibexBD}.CompanyApp`});
            return result;
        } catch (error) {
            return 0;
        }
    }

    /**
     * Permite obtener de manera paginada las compañias o marcas aliadas
     * @param from Indice al buscar
     * @param limit Cantidad de elementos a obtener a partir del indice establecido
     * @returns Lista de datos de las compañias
     */
    async GetCompaniesSliced(from: number, limit: number): Promise<CompanyInterface[]> {
        const offset = (from-1)*limit;
        try {
            const result: CompanyInterface[] = await this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.CompanyApp limit ${limit} offset ${offset}` });
            return result;
        } catch (error) {
            return null
        }
    }

    async GetCompany(id_company: string): Promise<CompanyInterface | null> {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE id = "${id_company}"`;
            const result: CompanyInterface[] = await this.db.GET_ANY_QUERY({ query });
            return result[0] || null;
        } catch (error) {
            return null;
        }
    }
    async GetCompaniesSelect(limit: number, offset: number, arrayCitiesId?: string[], arrayCategories?: string[], searchData?: string, acceptsCredit?: number): Promise<any[]> {
        try {
            let currentOff = (offset - 1) * limit;
            const search: string = searchData ? searchData : "";
            const citiesId = arrayCitiesId && arrayCitiesId.length > 0 ? `"${arrayCitiesId.map(idCitie => `${idCitie}`).join(",")}"` : `""`;
            const categories = arrayCategories && arrayCategories.length > 0 ? `"${arrayCategories.map(idCategory => `${idCategory}`).join(",")}"` : `""`;
            const numberSAW = acceptsCredit === 1 ? `${acceptsCredit}` : 'NULL';

            const query = `CALL companiesByCitiesV2(${currentOff}, ${limit}, ${citiesId}, ${categories}, "${search}", ${numberSAW})`;
            const result: CompanyInterface[] = await this.getDataFromStoredProcedure(query);
            return result;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    async SearchCompaniesSelect(name: any): Promise<any[]> {
        try {
            const query = `SELECT id,name FROM ${environment.clubFibexBD}.CompanyApp WHERE name LIKE("%${name}%")`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null
        }
    }
    async SearchCompaniesById(ids: string): Promise<any[]> {
        try {
            const query = `SELECT ca.id,ca.name FROM ${environment.clubFibexBD}.CompanyApp ca WHERE ca.id IN(${ids})`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null
        }
    }
    async GetProducts(idFranquiciaUser: string, nro_contrato: string): Promise<ProductInterface[]> {
        try {
            const query = `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`;
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);
            console.log(result);
            
            return result;
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async GetFranqByCurrentLocation(): Promise<FranquiciaInterface | null> {
        try {
            const currentLocation: LocationDataInterface = await this.GetLocation()

            if (currentLocation && currentLocation.address.city) {
                const userCity: string = `FIBEX ${currentLocation.address.city.toUpperCase()}`;
                const query = `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Nombre = "${userCity}"`;
                const result: FranquiciaInterface[] = await this.db.GET_ANY_QUERY({ query });
                return result[0] || null;
            }
            return null
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async GetBanners(idFranquiciaUser?: string): Promise<BannerInterface[]> {
        try {
            const result: BannerInterface[] = await this.db.GET_ANY_QUERY({
                query: `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic`
            });
            return result;
        } catch (error) {
            return null
        }
    }

    async GetFranquicia(idFranquiciaUser: string): Promise<FranquiciaInterface[]> {
        try {
            const result: FranquiciaInterface[] = await this.db.GET_ANY_QUERY({
                query: `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE idFranquicia = "${idFranquiciaUser}";`
            });
            return result;
        } catch (error) {
            return null
        }
    }

    async GetAllFranquicias(): Promise<FranquiciaInterface[]> {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Status = 1`;
            const result: FranquiciaInterface[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null;
        }
    }

    async GetProductBranchOffices(productID: string, franchiseID: string,idPromo:string): Promise<any> {
        try {
            const query = `CALL ProductoSucursales('${productID}','${franchiseID}','${idPromo}');`;
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } catch (error) {
            console.error(error);
            return null
        }
    }

    async GetAllData(table: string) {
        try {
            const query = `SELECT * FROM ${environment.bd}.${table}`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            console.error(error);
            return null
        }
    }

    async GetAllDataCampo(table: string, campo: string, valor: any): Promise<any[]> {
        try {
            const query = `SELECT * FROM ${environment.bd}.${table} WHERE ${campo} = ${JSON.stringify(valor)}`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    async ShowInfo() {
        try {
            if (this.ShowInfoTable && this.ShowInfoTable !== "") {
                return this.ShowInfoTable;
            } else {
                const query = `SELECT * FROM ${environment.bd}.ShowAllInfo`;
                const result: any = await this.db.GET_ANY_QUERY({ query });
                this.ShowInfoTable = result;
                return result;
            }
        } catch (error) {
            throw error;
        }
    }
    async CamposTableReturn(table: string) {
        try {
            if (table && table != "") {
                const query = `SELECT attr FROM ${environment.bd}.ShowAllInfo WHERE tableName = '${table}'`;
                const result: any[] = await this.db.GET_ANY_QUERY({ query });
                if (result && result.length > 0) {
                    const campos = result[0].attr.filter((camposexluido: any) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
                    return campos;
                } else
                    return [];
            } else {
                throw { error: "Error debes poner un nombre de tabla" }
            }
        } catch (error) {
            throw error;
        }
    }

    Test() {
        
    }

    public async handleProductFav(product: ProductInterface): Promise<void> {
        try {
            const user = this.AuthService.getUser();
            const { nro_contrato } = user;
            const { id } = product;
            const body = {
              idAbonado: nro_contrato,
              idProducto: id,
            };
            
            product.fav = !product.fav;
            const result: AxiosResponse<ResFav> = await axios.post<ResFav>(`${environment.apiTest}/${environment.favMethod}`, body)
         
        } catch (error) {
            console.error(error);
        }
    }

    public async filterProductById(productId: string, nro_contrato: string): Promise<ProductInterface | null> {
        try {
            let products: ProductInterface[] = await this.GetProducts(this.user.id_franq, nro_contrato)
            return products.find(product => productId === product.id)
        } catch (error) {
            return null
        }
    }

    async GetCoupons(idAbonado: string): Promise<CouponProductInterface[] | null> {

        try {
            const query =  `CALL Cupones("${idAbonado}");`
            const result: CouponProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } catch (error) {
            return null
        }
    }

    async postRating(rating: any){
        try {


            const body = rating;

            const result = await axios.post<any>(`${environment.apiTest}` + '/ReportCuponsGenerated' , body);
            
            return result.data;

        } catch (error) {
            return null
        }
    }

    async ProductoSucursales(productId: string): Promise<any[] | null> {

        try {
            const query = `CALL ProductoSucursales("${productId}");`;
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } catch (error) {
            return null
        }
    }

    async GetFavProducts(): Promise<Observable<ProductInterface[] | null>> {
        try {
            let productsFav: ProductInterface[] = await this.GetProducts(this.user.id_franq, this.user.nro_contrato)
            productsFav = productsFav.filter(product => product.fav)
            this.favProducts.next(productsFav)
            return this.favProducts.asObservable();
        } catch (error) {
            return null;
        }
    }

    async GetQR(product: ProductInterface, branchOfficeID: string = 'asdsd5',amountCredit?: number): Promise<QrInterface> {
        try {
            const { id, company, idAbonado, TipoCanjeo, CodigoFijo } = product

            const body: any = {
                idEmpConv: company,
                // idSucursal: 'asdsd5',
                idSucursal: branchOfficeID,
                idAbonado: idAbonado,
                idPromo: id,
                TipoCanjeo: TipoCanjeo,
                CodigoFijo: CodigoFijo
            }
            if(amountCredit && amountCredit>0) body.monto_gc = amountCredit;
            console.log(body);
            
             const result: AxiosResponse<QrInterface> = await axios.post<QrInterface>(`${environment.apiTest}/${environment.qrMethod}`, body)

            return result.data
        } catch (error) {
            console.error(error);
            return null

        }
    }

    public goToMainMenuEvent(): Observable<void> {
        this.goToMainMenuSubject.next()
        return this.goToMainMenuSubject.asObservable()
    }

    async GetLocation(): Promise<LocationDataInterface | null> {//
        if (navigator.geolocation) { //check if geolocation is available
            return new Promise<LocationDataInterface>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const result: AxiosResponse<LocationDataInterface> = await axios.get<LocationDataInterface>(`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`)
                    resolve(result.data)
                }, (error) => reject(error))
            })
        }
    }

    async getAdvertisingBanners() {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.cb_AdvertisingBanner WHERE status = 1;`
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;

        } catch (error) {
            return null
        }
    }
    async getMarksBanners() {
        try {
            const result: any[] = await this.db.GET_ANY_QUERY({
                query: `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic WHERE status = 1;`
            })
            return result;

        } catch (error) {
            return null
        }
    }

    async generateQRBill({ NombreProducto, description, idAbonado, price, Codigo, Cupon }) {
        const current = new Date();
        const body = {
            date: current.toLocaleDateString('en-GB'),
            time: current.toLocaleTimeString('en-US'),
            promoTitle: NombreProducto,
            subscriber: idAbonado,
            price,
            promoDescription: description,
            qrUrl: Cupon,
            qrCode: Codigo,
        }
        try {
            const result = await axios.post('https://apitest5.thomas-talk.me/generate-qr-bill', body)
            // const result = await axios.post('http://localhost:8200/generate-qr-bill', body)
            return result.data

        } catch (error) {
            console.error(error)
        }
    }

    public async getPopUpClubFibex(): Promise<string> {
        try {
            const result: IPopup[] = await this.db.GET_ANY_QUERY({
                query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'popUp' AND status = 1;`
            })
            const currentDate = new Date();
            this.addPopUpVideo(result);
            result[currentDate.getDate()]
            const image: string = result[new Date().getDate()].imgDesktop;
            this.localStorageService.set('srcPopUp',{
            'image':image,
            'currentDate': currentDate.getTime()
            })
            return image;

        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Agrega más videos popup si la longitud es menor a 31
     */
    public addPopUpVideo = (popupVideos:IPopup[]): void => {
        if (popupVideos.length <= 31 && this.popupIndexCount <= 31) {
            popupVideos.push(popupVideos[this.popupIndexCount])
            this.popupIndexCount++;
            this.addPopUpVideo(popupVideos)
        }
    }

    async getRecommendAndWinAd(): Promise<any[]> {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null;
        }
    }

    async getRecommendAndWinGif() {
        try {
            const result: any[] = await this.db.GET_ANY_QUERY({
                query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWinGif' AND status = 1;`
            });
            return result;

        } catch (error) {
            return null
        }
    }
     //METODOS CONSULTAS PAGINADOS
    //STORED PROCEDURES
    public async getCouponsPaginated(idAbonado: string, currentPage: number, limitPage: number): Promise<CouponProductInterface[] | null> {
        try {
            const offset = (currentPage - 1) * limitPage;
            const query = `CALL Cupones_pag("${idAbonado}", ${offset}, ${limitPage});`;
            const result: CouponProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    public async getProductsPaginated(idFranquiciaUser: string, nro_contrato: string,currentPage: number,limitPage: number,categories?: string,companies?: string,pricing?: string[], typeSearch?: number, textSearch?: string,qAreScratchWin?: number): Promise<ProductInterface[]>{
        try {
            console.log(qAreScratchWin);
            
            let totalPages = 0;
            let offset = currentPage;
            const data = categories ? `'${categories}'` : 'NULL';
            const dataCompany = companies ? `'${companies}'` : 'NULL';
            const dataPricing = pricing && pricing.length == 2 ? `${pricing[0]},${pricing[1]}` : `NULL,NULL`
            const numberTypeSearch = `${typeSearch || 0}`;
            const searchType = `"${textSearch || ""}"`;
            const numberSAW = qAreScratchWin === 1 ?`${qAreScratchWin}` : 'NULL';
            const query = `CALL getProductsFilteredV3('${idFranquiciaUser}','${nro_contrato}',${numberTypeSearch},${numberSAW},${searchType},${offset},${limitPage},${data},${dataCompany},${dataPricing});`
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);
            return result;
        } catch (error) {
            console.error(error)
            return null
        }
    } 

    public async getProductsSucPaginated(productId: string,currentPage: number,limitPage: number,  ){
        try {
            let totalPages = 0;
            let offset = (currentPage-1)*limitPage;
            const query = `CALL productoSsucursales_pag('${productId}',${offset},${limitPage});`;
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;

        } catch (error) {
            return null
        }
    } 
    //VISTAS Y TABLAS
    async GetAllDataPaginated(table: string,currentPage: number,limitPage: number,dataCampo ?: {campo: string, valor: any}[]) {
        return new Promise(async (resolve, reject) => {
            let conditionalsConcatenated = "";
            let offset = (currentPage-1)*limitPage;
            if(dataCampo){
            conditionalsConcatenated+="WHERE ";
            conditionalsConcatenated+= dataCampo.map(obj=>{
                let valor = typeof(obj.valor)=='string'? `'${obj.valor}'` :  `${obj.valor}`;
                return `${obj.campo} =  ${valor}`}
            ).join("AND");
            }
            const result: any[] = await this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.${table} ${conditionalsConcatenated} LIMIT ${limitPage} OFFSET ${offset}` })
            resolve( result );
        });
    }

    async getCompaniesPaginated(currentPage:number,limitPage:number): Promise<CompanyInterface[]> {
        try {
            const result: CompanyInterface[] = await this.GetAllDataPaginated("CompanyApp",currentPage,limitPage) as CompanyInterface[];
            return result;
        } catch (error) {
            return null
        }
    }
    async getBannersPaginated(): Promise<BannerInterface[]> {
       {
            try {
                const result: BannerInterface[] =await  this.GetAllDataPaginated("cb_BannerPublic",1,5,[{campo:"Status",valor:"1"}]) as BannerInterface[];
                return result;
            } catch (error) {
              
            }
        }
    }
    async searchProductsByName(idAbonado: string,dataSearch: string):Promise<ProductInterface[]>{

    try {
      //  alert("enviando: "+dataSearch);
      const query = `CALL searchProducts("${idAbonado}","${dataSearch}")`;
      const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);  
      return result;
    
    
    } catch (error) {
        console.error(error);
        return [];
}        
    }     
 
    async getAdvertisingBannersPaginated(): Promise<any[]> {
        try {
            
            const result: any[] = await  this.GetAllDataPaginated("cb_AdvertisingBanner",1,5,[{campo:"Status",valor:"1"}]) as any[];
            return result;    
        }
            
        catch (error) {
            return null
        }
    
    }
    
//7
    async getMarksBannersPaginated(): Promise<any[]>  {
        //cb_BannerPublic
        try {
            const result: any[] = await  this.GetAllDataPaginated("cb_BannerPublic",1,5,[{campo:"Status",valor:"1"}]) as any[];
            return result;    
        }    
        catch (error) {
            return null
        }
    }
    async gettPopUpsOM(): Promise<any[]> {
        try {
            const query = `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='popUpOM' AND status = 1;`;
            const result: any[] = await this.db.GET_ANY_QUERY({ query });
            return result;
        } catch (error) {
            return null;
        }
    }

    async gettVideoOM(): Promise<any[]> {
        try {
            const result: any[] = await this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='videoBanner' AND status = 1;` });
            return result;
        }       
        catch (error) {
            return null
        }
    }
    public processQREmpresa = async (QRcode: string, idEmpConv: string, CodigoEmpresa: string): Promise<any> => {
        try {
            const body = {
                QRcode,
                idEmpConv,
                CodigoEmpresa
            };
            const result: AxiosResponse = await axios.post(`${environment.apiTest}/ExchangeTienda`, body);
        
            
            if (result.data["message"] && result.data["message"] === "QR Aliado Invalido") {
                throw new Error(result.data["message"]);
            }
            if (!result.data['status']) {
                throw new Error(result.data['error']);
            }
    
            return result.data;
        } catch (error) {
            console.error(error);
            return Promise.reject({ error: error.name });
        }
    };
    
    public getCouponByID = async (idCupon: string) => {
        try {
            const result: any[] = await  this.db.GET_ANY_QUERY({query: `SELECT * FROM ${environment.clubFibexBD}.cb_ControlCupon WHERE idControlCupon="${idCupon}"`}) as any[];

            return Promise.resolve(result);
        }       
        catch (error) {
            return null
        }
    }
    public getDaysCoupon = async (QRcode): Promise<any>=>{
        try {
            const  dbCon: dbFullDataBase = new dbFullDataBase(environment.clubFibexBD);
            const response: any[] = await dbCon.GET_ANY_QUERY({query: `SELECT ccc.FechaGenerado, cpp.FechaLimite FROM cb_ControlCupon ccc 
            INNER JOIN cb_PromoProductos cpp ON(ccc.idPromo = cpp.idPromo)
            WHERE ccc.QR ="${QRcode}" AND ccc.Status = 1 AND ccc.Canjeado =0`});
            if(Array.isArray(response) && response.length>0){
                const data = response[0];
                return Promise.resolve({status: true, FechaGenerado: data.FechaGenerado, FechaLimite: data.FechaLimite})
            }
            else{
                return Promise.reject({status: false,message: "El cupon no se encuentra dentro de la promocion,  esta inactivo  o ha sido canjeado"});
            }
        }       
        catch (error) {
            console.error(error);
            return Promise.reject(null);
        }
    }
    public getRankingPromos = async ():Promise<any>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.apiTest}/getrankingcoupons`);

            const responseData = result.data;
            if(responseData.status && Array.isArray(responseData.data) && responseData.data.length>0){ 
                return Promise.resolve(responseData.data);
            }
            throw new Error("Error en obtener el ranking")
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    //Cambia la llamada a la base de datos con el objeto
    public getRecentPromos = async ():Promise<any>=>{
        try {
            const result: AxiosResponse<any> = await axios.get<any>(`${environment.apiTest}/getrecentpromos`);

            const responseData = result.data;
            if(responseData.status && Array.isArray(responseData.data) && responseData.data.length>0){
                return Promise.resolve(responseData.data);
            }
            throw new Error("Error en obtener el ranking")
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    } 
    public getFeaturedPromos = async (idAbonado:string):Promise<ProductInterface[]>=>{
        try {
            const query = `CALL promosFeatured('${idAbonado}');`;
            const result: ProductInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } 
        catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    public getFeaturedAllies = async ():Promise<any>=>{
        try {
            const query = `CALL getFeaturedAllies();`;
            const result: CompanyInterface[] = await this.getDataFromStoredProcedure(query);  
            return result;
        } catch (error) {
        console.error(error);
        return Promise.reject(error);   
        }
      
    }
    public getSucursales = async ():Promise<any>=>{
        const result: any[] = await  this.db.GET_ANY_QUERY({query: `SELECT * FROM ${environment.clubFibexBD}.AviableCities`});      
        return Promise.resolve(result);
    }
    public async getContactDateByEnterprise(idEmpConv: any){
        const result: any[] = await  this.db.GET_ANY_QUERY({query: `CALL getContactDateByEnterprise('${idEmpConv}');`});      
        return Promise.resolve(result);
    }
    private async getDataFromStoredProcedure(query: string): Promise<any[]> {
        try {
        const result: ProcedureResponse = await this.db.GET_ANY_QUERY({query}) as ProcedureResponse;    
        console.log(result[1]);
        return Object.values(result[0]);
        } catch (error) {
        console.error(error);
        return []            
        }
    }
    private startCacheUpdateInterval() {
        setInterval(() => {

        }, 60000); // Actualiza el caché cada 1 minuto
      }
}