import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { IonSlides, ModalController, NavController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
export class Card1Component {
    constructor(ClubFibexServ, navCtrl, sanitizier, modalCtrl) {
        this.ClubFibexServ = ClubFibexServ;
        this.navCtrl = navCtrl;
        this.sanitizier = sanitizier;
        this.modalCtrl = modalCtrl;
        this.isANewElement = false;
        this.featuredStyle = false;
        this.currentCategory = '';
        this.categories = [
            { name: 'Inicio', class: 'home' },
            { name: 'Gastronomía', class: 'gastronomy' },
            { name: 'Tecnología', class: 'technology' },
            { name: 'Salud y Bienestar', class: 'health' },
            { name: 'Educación y Deportes', class: 'sport' },
            { name: 'Turismo', class: 'turism' },
            { name: 'Retail', class: 'retail' },
            { name: 'Entretenimiento', class: 'entertainment' },
        ];
        this.slideBannerOptions = {
            // autoplay: true,
            autoplay: false
        };
        this.loadedProductImg = false;
        this.containerClass = '';
        const makeColor = () => {
            return (Math.round((Math.random() * (0xAA - 0x33))) + 0x33).toString(16).padStart(2, "0");
        };
        this.styleColorDiscount = this.sanitizier.bypassSecurityTrustStyle("color: #" + makeColor() + makeColor() + makeColor());
    }
    ngOnInit() {
        const index = this.categories.findIndex(eCategory => eCategory.name === this.item.Categoria);
        this.currentCategory = this.categories[index].class;
    }
    ngAfterViewInit() {
        // this.applyContainerClass();
    }
    addFavoriteItem(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.ClubFibexServ.handleProductFav(item);
        });
    }
    goToRestDetail() {
        // this.router.navigate(['club-fibex/product-details', this.item.id, false])
        this.navCtrl.navigateRoot(['club-fibex/product-details', this.item.id, false]);
    }
    parseString(image) {
        if (Array.isArray(image))
            return image;
        if (typeof image === "string")
            return JSON.parse(image);
        return null;
    }
    slideNext() {
        if (this.slides)
            this.slides.slideNext();
    }
    slidePrev() {
        if (this.slides)
            this.slides.slidePrev();
    }
    getColorRanking(value) {
        let color = "ranking_container ";
        if (value < 4)
            color += "ranking-top-place";
        else if (value < 7)
            color += "ranking-mid-place";
        else
            color += "ranking-last-place";
        return color;
    }
    applyContainerClass() {
        const containerWidth = this.containerCard.nativeElement.offsetWidth;
        if (containerWidth > 172) {
            this.containerClass = 'food_details_cards_larger';
        }
        else {
            this.containerClass = 'food_details_cards_small';
        }
    }
    parseStringToNumber(value) {
        return Number(value);
    }
}
