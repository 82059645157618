import * as tslib_1 from "tslib";
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
const categoryFields = [
    { name: 'Status' },
    { name: 'icon' },
    { name: 'idEmpCat', primary: true },
    { name: 'img' },
    { name: 'imgWhite' },
    { name: 'nombre' },
    { name: 'color' },
    { name: 'orden' },
    { name: 'isLoaded' }
];
const promotionFields = [
    { name: 'id', primary: true },
    { name: 'referImages' },
    { name: 'isDiscount' },
    { name: 'AplicaDelivery' },
    { name: 'CantCuponesUser' },
    { name: 'Categoria' },
    { name: 'CuponesDiponible' },
    { name: 'FechaFin' },
    { name: 'FechaIni' },
    { name: 'NombreProducto' },
    { name: 'Pickup' },
    { name: 'category' },
    { name: 'company' },
    { name: 'company_logo' },
    { name: 'description' },
    { name: 'discount' },
    { name: 'discountPrice' },
    { name: 'idEnSAE' },
    { name: 'idProducto' },
    { name: 'img' },
    { name: 'name' },
    { name: 'price' },
    { name: 'ranking' },
    { name: 'fav' },
    { name: 'idAbonado' },
    { name: 'LinkRedirect' },
    { name: 'LinkPromo' },
    { name: 'productPrice' },
    { name: 'productPercent' },
    { name: 'QRurl' },
    { name: 'idCupon' },
    { name: 'createdAt' },
    { name: 'updatedAt' },
    { name: 'orden' },
    { name: 'TipoCanjeo' },
    { name: 'CodigoFijo' },
    { name: 'companyid' },
    { name: 'aceptaCred' },
    { name: 'montoCred' }
];
const couponFields = [
    { name: 'companyid' },
    { name: 'FechaFin' },
    { name: 'FechaLimite' },
    { name: 'QRurl' },
    { name: 'QR' },
    { name: 'LinkRedirect' },
    { name: 'LinkPromo' },
    { name: 'idAbonado' },
    { name: 'category' },
    { name: 'company' },
    { name: 'company_logo' },
    { name: 'createdAt' },
    { name: 'description' },
    { name: 'discount' },
    { name: 'discountPrice' },
    { name: 'id', primary: true },
    { name: 'idCupon' },
    { name: 'img' },
    { name: 'isDiscount' },
    { name: 'name' },
    { name: 'price' },
    { name: 'usado' },
    { name: 'TipoCanjeo' },
    { name: 'idEmpConv' },
    { name: 'Comentario' },
    { name: 'rating' },
    { name: 'idSucursal' },
    { name: 'idProducto' }
];
const companyFields = [
    { name: 'Orden' },
    { name: 'UrlConvenio' },
    { name: 'id', primary: true },
    { name: 'idEnSAE' },
    { name: 'img' },
    { name: 'name' },
    { name: 'rif' },
    { name: 'namesucursal' },
    { name: 'companyBanner' },
    { name: 'ordenFeat' },
    { name: 'aceptaCred' },
    { name: 'category' }
];
const locationFields = [
    { name: 'Nombre' },
    { name: 'idEnSAE' },
    { name: 'idSucursal', primary: true }
];
const modelsFibextelecom = [
    { name: 'promotions', fields: promotionFields },
    { name: 'featured_promotions', fields: promotionFields },
    { name: 'ranking_promotions', fields: promotionFields },
    { name: 'favroite_promotions', fields: promotionFields },
    { name: 'companies', fields: companyFields },
    { name: 'locations', fields: locationFields },
    { name: 'coupons', fields: couponFields },
    { name: 'categories', fields: categoryFields }
];
// const modelsFibextelecom: IModelTable[] = []
export class IndexDBService {
    constructor(localStorage) {
        this.localStorage = localStorage;
        this.initializingDB = [];
    }
    inicializeDB(database, models) {
        return new Promise((resolve, reject) => {
            try {
                const requestOpen = indexedDB.open(database);
                requestOpen.onsuccess = function () {
                    resolve(requestOpen.result);
                };
                requestOpen.onerror = function (ev) {
                    reject(requestOpen.error);
                };
                requestOpen.onupgradeneeded = function (ev) {
                    const db = requestOpen.result;
                    for (const modelItem of models) {
                        const autoincrement = modelItem.fields.find(fieldItem => fieldItem.primary && fieldItem.autoincrement);
                        const primary = modelItem.fields.find(fieldItem => fieldItem.primary);
                        const fields = modelItem.fields.filter(fieldItem => fieldItem !== primary && fieldItem !== autoincrement);
                        const storage = db.createObjectStore(modelItem.name, {
                            keyPath: ((autoincrement || primary) && (autoincrement || primary).name),
                            autoIncrement: !!autoincrement
                        });
                        // Crear indexados
                        fields.forEach(fieldItem => {
                            const index = storage.createIndex(fieldItem.name + "_index", fieldItem.name, { unique: fieldItem.index });
                        });
                    }
                };
            }
            catch (err) {
                reject(err);
            }
        });
    }
    getDatabase() {
        return new Promise((resolve, reject) => {
            try {
                if (this.db) {
                    resolve(this.db);
                }
                else {
                    if (!this.initializingDB.length) {
                        this.inicializeDB("fibextelecom", modelsFibextelecom)
                            .then((db) => {
                            this.db = db;
                            const temp = this.initializingDB;
                            this.initializingDB = [];
                            temp.forEach(initializingItem => {
                                initializingItem.resolve(db);
                            });
                        })
                            .catch(err => {
                            const temp = this.initializingDB;
                            this.initializingDB = [];
                            temp.forEach(initializingItem => {
                                initializingItem.reject(err);
                            });
                        });
                    }
                    this.initializingDB.push({
                        resolve,
                        reject
                    });
                }
            }
            catch (err) {
                reject(err);
            }
        });
    }
    getTable(table) {
        return new Promise((resolve, reject) => {
            try {
                this.getDatabase()
                    .then(db => {
                    if (!db.objectStoreNames.contains(table))
                        throw new Error("Does not exists the table '" + table + "'");
                    /**
                     * Instance of ITable
                     */
                    const tableItem = {
                        findAll(condition) {
                            return new Promise((resolve, reject) => {
                                try {
                                    const transaction = db.transaction([table], 'readonly');
                                    const requestCursor = transaction.objectStore(table).openCursor();
                                    const resultData = [];
                                    let offset = 0;
                                    requestCursor.onsuccess = (ev) => {
                                        const cursor = requestCursor.result;
                                        if (cursor && (!(condition && condition.limit) || resultData.length < condition.limit)) {
                                            if (!(condition && condition.offset) || offset >= condition.offset) {
                                                let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                                                    return condition.where[keyName] == cursor.value[keyName];
                                                });
                                                if (result) {
                                                    resultData.push(cursor.value);
                                                }
                                            }
                                            cursor.continue();
                                        }
                                        else
                                            resolve(resultData);
                                    };
                                    requestCursor.onerror = (ev) => {
                                        reject(requestCursor.error);
                                    };
                                }
                                catch (err) {
                                    reject(err);
                                }
                            });
                        },
                        delete(condition) {
                            return new Promise((resolve, reject) => {
                                try {
                                    const transaction = db.transaction([table], 'readwrite');
                                    const requestCursor = transaction.objectStore(table).openCursor();
                                    let offset = 0;
                                    requestCursor.onsuccess = (ev) => {
                                        const cursor = requestCursor.result;
                                        if (cursor) {
                                            if (!(condition && condition.offset) || offset >= condition.offset) {
                                                let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                                                    return condition.where[keyName] == cursor.value[keyName];
                                                });
                                                if (result) {
                                                    const request = cursor.delete();
                                                    request.onsuccess = function () {
                                                        console.log("Registro eliminado");
                                                    };
                                                    request.onerror = function () {
                                                        console.log("Hubo un error al eliminar el registro");
                                                    };
                                                }
                                            }
                                            cursor.continue();
                                        }
                                        else
                                            resolve();
                                    };
                                    requestCursor.onerror = (ev) => {
                                        reject(requestCursor.error);
                                    };
                                }
                                catch (err) {
                                    reject(err);
                                }
                            });
                        },
                        findOne(condition) {
                            return new Promise((resolve, reject) => {
                                try {
                                    const transaction = db.transaction([table], 'readonly');
                                    const requestCursor = transaction.objectStore(table).openCursor();
                                    let offset = 0;
                                    requestCursor.onsuccess = (ev) => {
                                        const cursor = requestCursor.result;
                                        if (cursor) {
                                            if (!(condition && condition.offset) || offset >= condition.offset) {
                                                let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                                                    return condition.where[keyName] == cursor.value[keyName];
                                                });
                                                if (result) {
                                                    return resolve(cursor.value);
                                                }
                                            }
                                            cursor.continue();
                                        }
                                        else
                                            resolve(null);
                                    };
                                    requestCursor.onerror = (ev) => {
                                        reject(requestCursor.error);
                                    };
                                }
                                catch (err) {
                                    reject(err);
                                }
                            });
                        },
                        getName() {
                            return table;
                        },
                        insert(reg) {
                            return new Promise((resolve, reject) => {
                                try {
                                    const transaction = db.transaction([table], 'readwrite');
                                    const objectStorage = transaction.objectStore(table);
                                    const adds = reg instanceof Array ? reg : [reg];
                                    Promise.all(adds.map(addItem => {
                                        return new Promise((resolve, reject) => {
                                            try {
                                                const request = objectStorage.add(addItem);
                                                request.onsuccess = (ev) => {
                                                    resolve(request.result);
                                                };
                                                request.onerror = (ev) => {
                                                    reject(request.error);
                                                };
                                            }
                                            catch (err) {
                                                reject(err);
                                            }
                                        });
                                    }))
                                        .then((resultAll) => {
                                        resolve(resultAll);
                                    })
                                        .catch(reject);
                                }
                                catch (err) {
                                    reject(err);
                                }
                            });
                        },
                        update(newData, condition) {
                            return new Promise((resolve, reject) => {
                                try {
                                    const transaction = db.transaction([table], 'readwrite');
                                    const requestCursor = transaction.objectStore(table).openCursor();
                                    let offset = 0;
                                    requestCursor.onsuccess = (ev) => {
                                        const cursor = requestCursor.result;
                                        let n_affected = 0;
                                        if (cursor) {
                                            if (!(condition && condition.offset) || offset >= condition.offset) {
                                                let result = !(condition && condition.where) || Object.keys(condition.where).every(keyName => {
                                                    return condition.where[keyName] == cursor.value[keyName];
                                                });
                                                if (result) {
                                                    n_affected++;
                                                    const request = cursor.update(Object.assign({}, cursor.value, newData));
                                                    request.onsuccess = function () {
                                                        console.log("Registro insertado...");
                                                    };
                                                    request.onerror = function () {
                                                        reject(request.error);
                                                    };
                                                }
                                            }
                                            cursor.continue();
                                        }
                                        else
                                            resolve({ n_affected });
                                    };
                                    requestCursor.onerror = (ev) => {
                                        reject(requestCursor.error);
                                    };
                                }
                                catch (err) {
                                    reject(err);
                                }
                            });
                        }
                    };
                    resolve(tableItem);
                })
                    .catch(reject);
            }
            catch (err) {
                reject(err);
            }
        });
    }
    inicializeIndexDB() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const db = yield this.inicializeDB("fibextelecom", modelsFibextelecom);
                console.log(db);
                console.log(db.objectStoreNames);
                // this.localStorage.setWithExpiry("categories","categories",ExpirationTime.thirthySeconds);
                //crear un objeto cache con todas las tablas y agregar un tiempo de expiracion para cada una
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    test() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // this.inicializeIndexDB();
            //  const table  = await this.getTable<{id_cantidad: number}>("tm_cantidad");
            //  table.insert({id_cantidad: 1});
            //  table.findAll({where:{id_cantidad: 3}})
        });
    }
}
IndexDBService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IndexDBService_Factory() { return new IndexDBService(i0.ɵɵinject(i1.LocalStorageService)); }, token: IndexDBService, providedIn: "root" });
