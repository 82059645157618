import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConsultasService } from './consultas.service';
import { LocalStorageService } from './local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import { OptionMenu } from '../providers';

@Injectable({
  providedIn: 'root'
})

export class EstadisticasService {
  RutaActual: String = ''
  ListAcciones: any = []

  constructor(
    private _http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _authentication: UserAuthenticationService,
    private _logs: LogErrorsService,
    private security: SeguridadDatos,
    private _consultas: ConsultasService
  ) {
    this.InitTimer()
  }

  async GetAcciones() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headersData = {
          directory: "Consultas",
          method: "GetActionsApp",
          token: environment.TokenUpdate,
          platform: "App"
        };

        this.security.EncrypDataHash(headersData).then((headers: any) => { // encabezados encriptados
          // peticion legal de los datos y encabezados encriptados
          this._http.get(environment.URLApi, { headers }).subscribe(data => {
            resolve(this.security.Descryp(data))
          }, (error: any) => {
            this._logs.insertarError(error.message);
            reject(error)
          })
        }).catch((err) => console.error(err));

      } catch (error) {
        this._logs.insertarError(error.message);
      }
    })
  }

  GetIdAccion(Ruta: string) {
    try {
      const ListMenu = this._consultas.MenuApp || OptionMenu
      const OpcionSelect: any = ListMenu.find((FO: any) => FO.url === Ruta)
      if (OpcionSelect) {
        const Accion: any = this.ListAcciones.find((Fa: any) => Fa.accion === OpcionSelect.title)
        if (Accion) {
          return Accion.idAccion
        } else {
          return null
        }
      } else {
        return null
      }

    } catch (error) {
      console.error(error)
      return null
    }
  }

  async GuardarAcciones() {
    try {
      //const data = Object.values(this._localStorageService.get("acciones")) || [];
      const DataAcciones: any = this._localStorageService.get("acciones")

      if (DataAcciones && DataAcciones.length > 0) {
        const headersData = {
          directory: "Envio",
          method: "RegisterActionsApp",
          token: environment.TokenUpdate,
          platform: "App"
        };
        this.security.EncrypDataHash(headersData).then((headers: any) => { // encabezados encriptados
          // peticion legal de los datos y encabezados encriptados
          this.security.EncrypDataHash(DataAcciones).then((DataBody: any) => { // encabezados encriptados
            this._http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
              this._localStorageService.remove("acciones")
            }, (error: any) => {
              this._logs.insertarError(error.message);
              console.error(error)
            })
          }).catch((err) => console.error(err));
        }).catch((err) => console.error(err));
      }

    } catch (error) {
      this._logs.insertarError(error.message);
    }
  }

  RegisterAccion(Ruta: string) {
    try {
      let AccionesRegistradas = this._localStorageService.get("acciones") || [];
      let Fecha: any = new Date()
      Fecha = Fecha.getUTCFullYear() + '-' +
        ('00' + (Fecha.getUTCMonth() + 1)).slice(-2) + '-' +
        ('00' + Fecha.getUTCDate()).slice(-2) + ' ' +
        ('00' + Fecha.getHours()).slice(-2) + ':' +
        ('00' + Fecha.getMinutes()).slice(-2) + ':' +
        ('00' + Fecha.getUTCSeconds()).slice(-2);

      const user = this._authentication.getUser();
      // const cedulaCliente = this._localStorageService.get('cedulaCliente');
      let contrato: any

      if (user) {
        contrato = user.nro_contrato;
        // if (cedulaCliente) {
        //   contrato = cedulaCliente.NContrato;
        // } else {
        //   const cedulas = this._localStorageService.get("cedulas");
        //   if (cedulas && cedulas[0]) {
        //     contrato = cedulas[0].numContratos[0];
        //   } else {
        //     contrato = user.cedula;
        //   }
        // }

        const Id_Accion = this.GetIdAccion(Ruta)
        if (Id_Accion != null) {
          AccionesRegistradas.push({
            idAccion: Id_Accion,
            identidad: user.cedula,
            contrato,
            fecha: Fecha,
          })
          this._localStorageService.set("acciones", AccionesRegistradas);
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  InitTimer() {
    setTimeout(() => {
      this.GuardarAcciones()
    }, 1200000);
  }

}
