import { Injectable } from '@angular/core';
import {  ModalController, PopoverController } from '@ionic/angular';
import { Modal1Component } from '../shared/components/modals/modal1/modal1.component';
import { ModalLocationComponent } from '../shared/components/modals/modal-location/modal-location.component';
import { FilterComponent } from '../shared/components/filter/filter.page';
import { Modal2Component } from '../shared/components/modals/modal2/modal2.component';
import { ModalRegisterCodeComponent } from '../shared/components/modal-register-code/modal-register-code.component';
import { ModalMessageComponent } from '../shared/components/modals/modal-message/modal-message.component';
import { buttonInterface } from '../interfaces/button-interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
 //PopOver   
 private popoverController: PopoverController,
 //Modal
 private modalController: ModalController,
 //Filters
  ) { }


  async generateModal(){
    const popup = await this.popoverController.create({
      component: ModalRegisterCodeComponent,
      cssClass: 'popover_small',
      mode: 'ios',
      // componentProps: {
      // cancelTextBtn: 'Cancelar',
      // successTextBtn: 'Aceptar',
      // title:  'Prueba nueva',
      // message: 'Este es el nuevo servicio para mostrar los modals'
      // }
    }) 
    return popup.present();
  }
  async genrateModalFull(){
    const popup = await this.modalController.create({
      component: FilterComponent,
      mode: 'ios',
      // componentProps: {
      // cancelTextBtn: 'Cancelar',
      // successTextBtn: 'Aceptar',
      // title:  'Prueba nueva',
      // message: 'Este es el nuevo servicio para mostrar los modals'
      // }
    }) 
    return popup.present();
  }
  async generateModalSuccess(titulo: string, mensaje: string, botonText: string,buttons?: buttonInterface[]){
    const popup = await this.popoverController.create({
      component: ModalMessageComponent,
      // translucent: true,
      mode: 'ios',
      cssClass: 'popover_small',
      componentProps: {
        type: 'success',
         title: titulo,
         message: mensaje,
         actionText: botonText,
         buttonList: buttons
      }
    }) 
    return popup.present();
  }
  async generateModalError(titulo: string, mensaje: string, botonText: string,buttons?: buttonInterface[]){
    let   componentProps: any = {
      type: 'error',
       title: titulo,
       message: mensaje,
       actionText: botonText,

    }
    if(buttons) componentProps.buttonList =buttons;  
    const popup = await this.popoverController.create({
      component: ModalMessageComponent,
      // translucent: true,
      mode: 'ios',
      cssClass: 'popover_small',
      componentProps
    }) 
    return popup.present();
  }
  async generateModalNumberForm(titulo: string, mensaje: string, botonText: string,buttons?: buttonInterface[]){
    const popup = await this.popoverController.create({
      component: Modal2Component,
      // translucent: true,
      mode: 'ios',
      cssClass: 'popover_small',
      componentProps: {
        type: 'success',
         title: titulo,
         message: mensaje,
         actionText: botonText,
        maxAmount: 20,
        amountProduct: 40,
        formPlaceholder: 'Ingresa monto',
        formType: 'number' 
        //  buttonList: buttons
      }
    }) 
    return popup.present();
  }  
}
