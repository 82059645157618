import * as tslib_1 from "tslib";
import axios from "axios";
import { environment } from '../../environments/environment';
import { CompanyInterface, GetCategoriesInteface, ProductInterface, BannerInterface, CouponProductInterface } from '../interfaces/product.interface';
import { LocalStorageService } from './local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { dbFullDataBase } from "../lib/dbfullv2";
import { SeguridadDatos } from "./bscript.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./user-authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "./bscript.service";
export class ClubFibexFeedingService {
    constructor(http, localStorageService, AuthService, router, enc) {
        this.http = http;
        this.localStorageService = localStorageService;
        this.AuthService = AuthService;
        this.router = router;
        this.enc = enc;
        this.ShowInfoTable = "";
        this.Excluido = ["createdAt", "updatedAt"];
        this.favProducts = new BehaviorSubject([]);
        this.goToMainMenuSubject = new BehaviorSubject(null);
        this.popupIndexCount = 0;
        this.db = new dbFullDataBase(environment.clubFibexBD);
        /**
         * Agrega más videos popup si la longitud es menor a 31
         */
        this.addPopUpVideo = (popupVideos) => {
            if (popupVideos.length <= 31 && this.popupIndexCount <= 31) {
                popupVideos.push(popupVideos[this.popupIndexCount]);
                this.popupIndexCount++;
                this.addPopUpVideo(popupVideos);
            }
        };
        this.processQREmpresa = (QRcode, idEmpConv, CodigoEmpresa) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const body = {
                    QRcode,
                    idEmpConv,
                    CodigoEmpresa
                };
                const result = yield axios.post(`${environment.apiTest}/ExchangeTienda`, body);
                if (result.data["message"] && result.data["message"] === "QR Aliado Invalido") {
                    throw new Error(result.data["message"]);
                }
                if (!result.data['status']) {
                    throw new Error(result.data['error']);
                }
                return result.data;
            }
            catch (error) {
                console.error(error);
                return Promise.reject({ error: error.name });
            }
        });
        this.getCouponByID = (idCupon) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.cb_ControlCupon WHERE idControlCupon="${idCupon}"` });
                return Promise.resolve(result);
            }
            catch (error) {
                return null;
            }
        });
        this.getDaysCoupon = (QRcode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const dbCon = new dbFullDataBase(environment.clubFibexBD);
                const response = yield dbCon.GET_ANY_QUERY({ query: `SELECT ccc.FechaGenerado, cpp.FechaLimite FROM cb_ControlCupon ccc 
            INNER JOIN cb_PromoProductos cpp ON(ccc.idPromo = cpp.idPromo)
            WHERE ccc.QR ="${QRcode}" AND ccc.Status = 1 AND ccc.Canjeado =0` });
                if (Array.isArray(response) && response.length > 0) {
                    const data = response[0];
                    return Promise.resolve({ status: true, FechaGenerado: data.FechaGenerado, FechaLimite: data.FechaLimite });
                }
                else {
                    return Promise.reject({ status: false, message: "El cupon no se encuentra dentro de la promocion,  esta inactivo  o ha sido canjeado" });
                }
            }
            catch (error) {
                console.error(error);
                return Promise.reject(null);
            }
        });
        this.getRankingPromos = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.apiTest}/getrankingcoupons`);
                const responseData = result.data;
                if (responseData.status && Array.isArray(responseData.data) && responseData.data.length > 0) {
                    return Promise.resolve(responseData.data);
                }
                throw new Error("Error en obtener el ranking");
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        //Cambia la llamada a la base de datos con el objeto
        this.getRecentPromos = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.apiTest}/getrecentpromos`);
                const responseData = result.data;
                if (responseData.status && Array.isArray(responseData.data) && responseData.data.length > 0) {
                    return Promise.resolve(responseData.data);
                }
                throw new Error("Error en obtener el ranking");
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getFeaturedPromos = (idAbonado) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL promosFeatured('${idAbonado}');`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getFeaturedAllies = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL getFeaturedAllies();`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        });
        this.getSucursales = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.AviableCities` });
            return Promise.resolve(result);
        });
        this.user = AuthService.getUser();
    }
    ngOnInit() {
        //this.ShowInfo();
    }
    GetCategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield axios.get(`${environment.UrlFull}api/v1/find-any-info/${environment.clubFibexBD}/cb_EmpCategoria/Status/1`);
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.CompanyApp`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    /**
     * Permite obtener la cantidad total de compañias
     */
    GetCountCompanies() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_LENGTH({ table: `${environment.clubFibexBD}.CompanyApp` });
                return result;
            }
            catch (error) {
                return 0;
            }
        });
    }
    /**
     * Permite obtener de manera paginada las compañias o marcas aliadas
     * @param from Indice al buscar
     * @param limit Cantidad de elementos a obtener a partir del indice establecido
     * @returns Lista de datos de las compañias
     */
    GetCompaniesSliced(from, limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const offset = (from - 1) * limit;
            try {
                const result = yield this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.CompanyApp limit ${limit} offset ${offset}` });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompany(id_company) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.CompanyApp WHERE id = "${id_company}"`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result[0] || null;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCompaniesSelect(limit, offset, arrayCitiesId, arrayCategories, searchData, acceptsCredit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let currentOff = (offset - 1) * limit;
                const search = searchData ? searchData : "";
                const citiesId = arrayCitiesId && arrayCitiesId.length > 0 ? `"${arrayCitiesId.map(idCitie => `${idCitie}`).join(",")}"` : `""`;
                const categories = arrayCategories && arrayCategories.length > 0 ? `"${arrayCategories.map(idCategory => `${idCategory}`).join(",")}"` : `""`;
                const numberSAW = acceptsCredit === 1 ? `${acceptsCredit}` : 'NULL';
                const query = `CALL companiesByCitiesV2(${currentOff}, ${limit}, ${citiesId}, ${categories}, "${search}", ${numberSAW})`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    SearchCompaniesSelect(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT id,name FROM ${environment.clubFibexBD}.CompanyApp WHERE name LIKE("%${name}%")`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    SearchCompaniesById(ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT ca.id,ca.name FROM ${environment.clubFibexBD}.CompanyApp ca WHERE ca.id IN(${ids})`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetProducts(idFranquiciaUser, nro_contrato) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL ProudctAppv2('${idFranquiciaUser}','${nro_contrato}');`;
                const result = yield this.getDataFromStoredProcedure(query);
                console.log(result);
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetFranqByCurrentLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const currentLocation = yield this.GetLocation();
                if (currentLocation && currentLocation.address.city) {
                    const userCity = `FIBEX ${currentLocation.address.city.toUpperCase()}`;
                    const query = `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Nombre = "${userCity}"`;
                    const result = yield this.db.GET_ANY_QUERY({ query });
                    return result[0] || null;
                }
                return null;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetBanners(idFranquiciaUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({
                    query: `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic`
                });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetFranquicia(idFranquiciaUser) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({
                    query: `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE idFranquicia = "${idFranquiciaUser}";`
                });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetAllFranquicias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.cb_Franquicia WHERE Status = 1`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetProductBranchOffices(productID, franchiseID, idPromo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL ProductoSucursales('${productID}','${franchiseID}','${idPromo}');`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetAllData(table) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.bd}.${table}`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    GetAllDataCampo(table, campo, valor) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.bd}.${table} WHERE ${campo} = ${JSON.stringify(valor)}`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    ShowInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.ShowInfoTable && this.ShowInfoTable !== "") {
                    return this.ShowInfoTable;
                }
                else {
                    const query = `SELECT * FROM ${environment.bd}.ShowAllInfo`;
                    const result = yield this.db.GET_ANY_QUERY({ query });
                    this.ShowInfoTable = result;
                    return result;
                }
            }
            catch (error) {
                throw error;
            }
        });
    }
    CamposTableReturn(table) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (table && table != "") {
                    const query = `SELECT attr FROM ${environment.bd}.ShowAllInfo WHERE tableName = '${table}'`;
                    const result = yield this.db.GET_ANY_QUERY({ query });
                    if (result && result.length > 0) {
                        const campos = result[0].attr.filter((camposexluido) => (camposexluido != "createdAt" && camposexluido != "updatedAt"));
                        return campos;
                    }
                    else
                        return [];
                }
                else {
                    throw { error: "Error debes poner un nombre de tabla" };
                }
            }
            catch (error) {
                throw error;
            }
        });
    }
    Test() {
    }
    handleProductFav(product) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const user = this.AuthService.getUser();
                const { nro_contrato } = user;
                const { id } = product;
                const body = {
                    idAbonado: nro_contrato,
                    idProducto: id,
                };
                product.fav = !product.fav;
                const result = yield axios.post(`${environment.apiTest}/${environment.favMethod}`, body);
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    filterProductById(productId, nro_contrato) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let products = yield this.GetProducts(this.user.id_franq, nro_contrato);
                return products.find(product => productId === product.id);
            }
            catch (error) {
                return null;
            }
        });
    }
    GetCoupons(idAbonado) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL Cupones("${idAbonado}");`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    postRating(rating) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const body = rating;
                const result = yield axios.post(`${environment.apiTest}` + '/ReportCuponsGenerated', body);
                return result.data;
            }
            catch (error) {
                return null;
            }
        });
    }
    ProductoSucursales(productId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `CALL ProductoSucursales("${productId}");`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    GetFavProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let productsFav = yield this.GetProducts(this.user.id_franq, this.user.nro_contrato);
                productsFav = productsFav.filter(product => product.fav);
                this.favProducts.next(productsFav);
                return this.favProducts.asObservable();
            }
            catch (error) {
                return null;
            }
        });
    }
    GetQR(product, branchOfficeID = 'asdsd5', amountCredit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const { id, company, idAbonado, TipoCanjeo, CodigoFijo } = product;
                const body = {
                    idEmpConv: company,
                    // idSucursal: 'asdsd5',
                    idSucursal: branchOfficeID,
                    idAbonado: idAbonado,
                    idPromo: id,
                    TipoCanjeo: TipoCanjeo,
                    CodigoFijo: CodigoFijo
                };
                if (amountCredit && amountCredit > 0)
                    body.monto_gc = amountCredit;
                console.log(body);
                const result = yield axios.post(`${environment.apiTest}/${environment.qrMethod}`, body);
                return result.data;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    goToMainMenuEvent() {
        this.goToMainMenuSubject.next();
        return this.goToMainMenuSubject.asObservable();
    }
    GetLocation() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (navigator.geolocation) { //check if geolocation is available
                return new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition((position) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        const result = yield axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json`);
                        resolve(result.data);
                    }), (error) => reject(error));
                });
            }
        });
    }
    getAdvertisingBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.cb_AdvertisingBanner WHERE status = 1;`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    getMarksBanners() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({
                    query: `SELECT * FROM ${environment.clubFibexBD}.cb_BannerPublic WHERE status = 1;`
                });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    generateQRBill({ NombreProducto, description, idAbonado, price, Codigo, Cupon }) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const current = new Date();
            const body = {
                date: current.toLocaleDateString('en-GB'),
                time: current.toLocaleTimeString('en-US'),
                promoTitle: NombreProducto,
                subscriber: idAbonado,
                price,
                promoDescription: description,
                qrUrl: Cupon,
                qrCode: Codigo,
            };
            try {
                const result = yield axios.post('https://apitest5.thomas-talk.me/generate-qr-bill', body);
                // const result = await axios.post('http://localhost:8200/generate-qr-bill', body)
                return result.data;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    getPopUpClubFibex() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({
                    query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'popUp' AND status = 1;`
                });
                const currentDate = new Date();
                this.addPopUpVideo(result);
                result[currentDate.getDate()];
                const image = result[new Date().getDate()].imgDesktop;
                this.localStorageService.set('srcPopUp', {
                    'image': image,
                    'currentDate': currentDate.getTime()
                });
                return image;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    getRecommendAndWinAd() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWin' AND status = 1;`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    getRecommendAndWinGif() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({
                    query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type = 'recAndWinGif' AND status = 1;`
                });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    //METODOS CONSULTAS PAGINADOS
    //STORED PROCEDURES
    getCouponsPaginated(idAbonado, currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const offset = (currentPage - 1) * limitPage;
                const query = `CALL Cupones_pag("${idAbonado}", ${offset}, ${limitPage});`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    getProductsPaginated(idFranquiciaUser, nro_contrato, currentPage, limitPage, categories, companies, pricing, typeSearch, textSearch, qAreScratchWin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                console.log(qAreScratchWin);
                let totalPages = 0;
                let offset = currentPage;
                const data = categories ? `'${categories}'` : 'NULL';
                const dataCompany = companies ? `'${companies}'` : 'NULL';
                const dataPricing = pricing && pricing.length == 2 ? `${pricing[0]},${pricing[1]}` : `NULL,NULL`;
                const numberTypeSearch = `${typeSearch || 0}`;
                const searchType = `"${textSearch || ""}"`;
                const numberSAW = qAreScratchWin === 1 ? `${qAreScratchWin}` : 'NULL';
                const query = `CALL getProductsFilteredV3('${idFranquiciaUser}','${nro_contrato}',${numberTypeSearch},${numberSAW},${searchType},${offset},${limitPage},${data},${dataCompany},${dataPricing});`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return null;
            }
        });
    }
    getProductsSucPaginated(productId, currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let totalPages = 0;
                let offset = (currentPage - 1) * limitPage;
                const query = `CALL productoSsucursales_pag('${productId}',${offset},${limitPage});`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    //VISTAS Y TABLAS
    GetAllDataPaginated(table, currentPage, limitPage, dataCampo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let conditionalsConcatenated = "";
                let offset = (currentPage - 1) * limitPage;
                if (dataCampo) {
                    conditionalsConcatenated += "WHERE ";
                    conditionalsConcatenated += dataCampo.map(obj => {
                        let valor = typeof (obj.valor) == 'string' ? `'${obj.valor}'` : `${obj.valor}`;
                        return `${obj.campo} =  ${valor}`;
                    }).join("AND");
                }
                const result = yield this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.${table} ${conditionalsConcatenated} LIMIT ${limitPage} OFFSET ${offset}` });
                resolve(result);
            }));
        });
    }
    getCompaniesPaginated(currentPage, limitPage) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.GetAllDataPaginated("CompanyApp", currentPage, limitPage);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    getBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            {
                try {
                    const result = yield this.GetAllDataPaginated("cb_BannerPublic", 1, 5, [{ campo: "Status", valor: "1" }]);
                    return result;
                }
                catch (error) {
                }
            }
        });
    }
    searchProductsByName(idAbonado, dataSearch) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //  alert("enviando: "+dataSearch);
                const query = `CALL searchProducts("${idAbonado}","${dataSearch}")`;
                const result = yield this.getDataFromStoredProcedure(query);
                return result;
            }
            catch (error) {
                console.error(error);
                return [];
            }
        });
    }
    getAdvertisingBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.GetAllDataPaginated("cb_AdvertisingBanner", 1, 5, [{ campo: "Status", valor: "1" }]);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    //7
    getMarksBannersPaginated() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //cb_BannerPublic
            try {
                const result = yield this.GetAllDataPaginated("cb_BannerPublic", 1, 5, [{ campo: "Status", valor: "1" }]);
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    gettPopUpsOM() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const query = `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='popUpOM' AND status = 1;`;
                const result = yield this.db.GET_ANY_QUERY({ query });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    gettVideoOM() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({ query: `SELECT * FROM ${environment.clubFibexBD}.cb_LandingBanner WHERE type='videoBanner' AND status = 1;` });
                return result;
            }
            catch (error) {
                return null;
            }
        });
    }
    getContactDateByEnterprise(idEmpConv) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.db.GET_ANY_QUERY({ query: `CALL getContactDateByEnterprise('${idEmpConv}');` });
            return Promise.resolve(result);
        });
    }
    getDataFromStoredProcedure(query) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.db.GET_ANY_QUERY({ query });
                console.log(result[1]);
                return Object.values(result[0]);
            }
            catch (error) {
                console.error(error);
                return [];
            }
        });
    }
    startCacheUpdateInterval() {
        setInterval(() => {
        }, 60000); // Actualiza el caché cada 1 minuto
    }
}
ClubFibexFeedingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClubFibexFeedingService_Factory() { return new ClubFibexFeedingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.UserAuthenticationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.SeguridadDatos)); }, token: ClubFibexFeedingService, providedIn: "root" });
