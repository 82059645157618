import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SeguridadDatos } from '../../../services/bscript.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { LogErrorsService } from '../../../services/log-errors.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
})
export class SecurityQuestionsComponent implements OnInit {
  
  // @Input() cedula: string = "";
  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() next: EventEmitter<{
    ask: string,
    answer: string
  }[]> = new EventEmitter();
  
  /**
   * Grupo de formulario
   */
  form: FormGroup;

  /**
   * Preguntas disponibles
   */
  questions: string[] = [
    "¿Cuál es el nombre de tu primera mascota?",
    "¿Cuál es tu ciudad de nacimiento?",
    "¿Cuál es tu color favorito?",
    "¿Cuál es el nombre de tu mejor amigo de la infancia?",
    "¿Cuál es el nombre de tu escuela primaria?",
    "¿Cuál es tu comida favorita?",
    "¿Cuál es el nombre de tu primer jefe?",
    "¿Cuál es tu película favorita?",
    "¿Cuál es el nombre de tu primer coche?",
    "¿Cuál es el nombre de tu libro favorito?",
  ];
  
  constructor(
      private fb: FormBuilder, 
  ) {
    this.form = this.fb.group({
      ask1: ["", [Validators.required]],
      answer1: ["", [Validators.required, Validators.minLength(3)]],

      ask2: ["", [Validators.required]],
      answer2: ["", [Validators.required, Validators.minLength(3)]],

      ask3: ["", [Validators.required]],
      answer3: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit() {

  }

  save() {
    return new Promise((resolve, reject) => {
      try {
        if(!this.form.invalid) {
          // Preguntas por registrar
          const questionsToRegister = [
            // Primera pregunta
            {
              ask: this.form.value['ask1'],
              answer: this.form.value['answer1']
            },
            // Segunda pregunta
            {
              ask: this.form.value['ask2'],
              answer: this.form.value['answer2']
            },
            // Tercera pregunta
            {
              ask: this.form.value['ask3'],
              answer: this.form.value['answer3']
            },
          ];
          this.next.emit(questionsToRegister);

          resolve(true);
        }
        else reject(new Error("Formulario inválido"));
      }
      catch(err) {
        reject(err);
      }
    });

  }

}
