import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

type sliderOptType = {pager:boolean,initialSlide?:number,dirección: string, navigation: boolean}

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, AfterViewInit {

  @ViewChild('slides', {static: true}) slides: IonSlides;
  @Input() sliderImages: string[]
  @Input() sliderActve: string | undefined;

  constructor() {
    
  }
  
  ngOnInit() {
    try {
      // this.findActiveIndex()
    } catch (error) {
      console.log(error);
    }
  }

  ngAfterViewInit(): void {
      this.findActiveIndex()
  }


  public findActiveIndex(): void {
    // this.sliderOptions.initialSlide = this.sliderActve ? this.sliderImages.findIndex((slide:string) => slide === this.sliderActve) : 1;
    
    if (this.sliderActve) {
      const indexActive = this.sliderImages.findIndex((slide:string) => slide === this.sliderActve)
      this.slides.slideTo(indexActive)
    }
  }

}
