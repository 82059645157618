import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
export class ContainerPromotionsComponent {
    constructor() {
        // TODO: Get promotions from API or service
        this.typePromotions = "";
        this.titleContainer = "";
        this.cssClass = "product-itemcard-2columns";
        this.pagination = false;
        this.promotions = [];
        this.areLoadingPromotions = true;
        //Outputs 
        this.onClickButtonTitle = new EventEmitter;
        this.onScrollEnd = new EventEmitter;
        //Public variables
        this.areAllProductsLoaded = false;
        //private variables
        this.limitPage = 15;
        this.handleSliderNav = (elemScroll, direction) => {
            const element = elemScroll.querySelector(".product_slider");
            if (element) {
                element.scrollBy({ left: direction === 'right' ? 272 : -272, behavior: 'smooth' });
            }
        };
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // TODO: Get promotions from API or service
        });
    }
    onInfiniteScroll() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const scrollEvent = event.target;
            if (scrollEvent.scrollLeft >= scrollEvent.scrollWidth - scrollEvent.offsetWidth && this.pagination) {
                {
                    const offset = this.promotions.length > 0 ? Math.round(this.promotions.length / this.limitPage) + 1 : 1;
                    // this.areLoadingProducts = true;
                    console.log("areLoadingProducts");
                    this.onScrollEnd.emit({ currentPage: offset, limitPage: this.limitPage });
                }
                //   this.areLoadingProducts= true;
                //   this.currentPage++;
                // const user = this.user.getUser();
                //   const getMorepromotions: ProductInterface[] = await this.promotionService.getSomePromotions("",user.nro_contrato,this.currentPage,this.limitPage);
                //   if(getMorepromotions.length>0){
                //     await new Promise(resolve => setTimeout(resolve, 500));
                //     this.promotions.push(...getMorepromotions)
                //   }
                //   this.areAllProductsLoaded = (getMorepromotions.length<this.limitPage);
                //   this.areLoadingProducts = false;
            }
        });
    }
    onClickTitleAction() {
        this.onClickButtonTitle.emit();
    }
    isDesktop() {
        return window.innerWidth > 768;
    }
}
