import * as tslib_1 from "tslib";
import { ModalController, PopoverController } from '@ionic/angular';
import { FilterComponent } from '../shared/components/filter/filter.page';
import { Modal2Component } from '../shared/components/modals/modal2/modal2.component';
import { ModalRegisterCodeComponent } from '../shared/components/modal-register-code/modal-register-code.component';
import { ModalMessageComponent } from '../shared/components/modals/modal-message/modal-message.component';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
export class ModalService {
    constructor(
    //PopOver   
    popoverController, 
    //Modal
    modalController) {
        this.popoverController = popoverController;
        this.modalController = modalController;
    }
    generateModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popup = yield this.popoverController.create({
                component: ModalRegisterCodeComponent,
                cssClass: 'popover_small',
                mode: 'ios',
            });
            return popup.present();
        });
    }
    genrateModalFull() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popup = yield this.modalController.create({
                component: FilterComponent,
                mode: 'ios',
            });
            return popup.present();
        });
    }
    generateModalSuccess(titulo, mensaje, botonText, buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popup = yield this.popoverController.create({
                component: ModalMessageComponent,
                // translucent: true,
                mode: 'ios',
                cssClass: 'popover_small',
                componentProps: {
                    type: 'success',
                    title: titulo,
                    message: mensaje,
                    actionText: botonText,
                    buttonList: buttons
                }
            });
            return popup.present();
        });
    }
    generateModalError(titulo, mensaje, botonText, buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let componentProps = {
                type: 'error',
                title: titulo,
                message: mensaje,
                actionText: botonText,
            };
            if (buttons)
                componentProps.buttonList = buttons;
            const popup = yield this.popoverController.create({
                component: ModalMessageComponent,
                // translucent: true,
                mode: 'ios',
                cssClass: 'popover_small',
                componentProps
            });
            return popup.present();
        });
    }
    generateModalNumberForm(titulo, mensaje, botonText, buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const popup = yield this.popoverController.create({
                component: Modal2Component,
                // translucent: true,
                mode: 'ios',
                cssClass: 'popover_small',
                componentProps: {
                    type: 'success',
                    title: titulo,
                    message: mensaje,
                    actionText: botonText,
                    maxAmount: 20,
                    amountProduct: 40,
                    formPlaceholder: 'Ingresa monto',
                    formType: 'number'
                    //  buttonList: buttons
                }
            });
            return popup.present();
        });
    }
}
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.PopoverController), i0.ɵɵinject(i1.ModalController)); }, token: ModalService, providedIn: "root" });
