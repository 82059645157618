export enum ExpirationTime {
    thirthySeconds = 30000,     // 30 segundos en milisegundos
    OneMinute = 60000,          // 1 minuto en milisegundos
    fifteenMinutes = 900000,     // 50 minutos en milisegundos
    thirtyMinutes = 1800000,    // 30 minutos en milisegundos
    OneHour = 3600000,          // 1 hora en milisegundos
    TwoHours = 7200000,         // 2 horas en milisegundos
    ThreeHours = 10800000,      // 3 horas en milisegundos
    TwelveHours = 43200000,     // 12 horas en milisegundos
    TwentyFourHours = 86400000, // 24 horas en milisegundos
    OneWeek = 604800000         // 1 semana en milisegundos
  }
  