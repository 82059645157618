import * as tslib_1 from "tslib";
import { OnChanges, OnInit, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ClubFibexFeedingService } from '../../../services/club-fibex-feeding.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { ActivatedRoute } from '@angular/router';
var FILTER_ACTIONS;
(function (FILTER_ACTIONS) {
    FILTER_ACTIONS["qCategory"] = "[FILTER] queryCategory";
    FILTER_ACTIONS["qRanking"] = "[FILTER] queryRanking";
    FILTER_ACTIONS["qPricing"] = "[FILTER] queryPricing";
    FILTER_ACTIONS["qCompanies"] = "[FILTER] queryCompanies";
    FILTER_ACTIONS["qCities"] = "[FILTER] queryCities";
    FILTER_ACTIONS["qTabDiscountType"] = "[FILTER] queryDiscountType";
    FILTER_ACTIONS["qSracthWin"] = "[FILTER] querySracthWin";
})(FILTER_ACTIONS || (FILTER_ACTIONS = {}));
var COUPON_MONEY_TYPE;
(function (COUPON_MONEY_TYPE) {
    COUPON_MONEY_TYPE["DISCOUNT"] = "discount";
    COUPON_MONEY_TYPE["DOLLAR"] = "dollar";
})(COUPON_MONEY_TYPE || (COUPON_MONEY_TYPE = {}));
export class ResultsSearchComponent {
    constructor(clubService, athentication, route) {
        this.clubService = clubService;
        this.athentication = athentication;
        this.route = route;
        this.textResult = "";
        this.closeEventEmit = new EventEmitter();
        this.areLoadingProducts = true;
        this.isEnableLoadProducts = true;
        this.productsSearched = [];
        this.allProducstSearched = [];
        this.currentLimit = 4;
        this.currentOffset = 1;
        this.qCompanies = [];
        this.qCategory = [];
        this.qCities = [];
        this.qPricing = [];
        this.qTypeSearch = 0;
        this.qSracthWin = 0;
        this.infiniteScroll = (event) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const scrollEvent = event.target;
                if (this.isEnableLoadProducts) {
                    this.currentOffset++;
                    yield this.getProductsBySearching(this.textResult);
                }
                else
                    event.target.disabled = true;
                event.target.complete();
            }
            catch (error) {
                console.error(error);
            }
        });
        this.user = this.athentication.getUser();
        this.loadRouterParamsCat();
        this.subjectSearch = new Subject();
        this.subjectSearch
            .pipe(debounceTime(300))
            .subscribe(resp => {
            try {
                if (resp) {
                    this.currentOffset = 1;
                    if (resp.length >= 1) {
                        this.areLoadingProducts = true;
                        const responseSearchLocal = this.allProducstSearched.filter(product => product.name.toLowerCase().includes(resp.toLowerCase()));
                        if (!responseSearchLocal.length) {
                            this.productsSearched = [];
                            this.getProductsBySearching(resp);
                        }
                        else {
                            this.productsSearched = responseSearchLocal;
                            this.areLoadingProducts = false;
                        }
                        //   this.findAllysByName(resp);
                    }
                }
                else {
                    //   this.marks = this.allMarks;
                }
            }
            catch (error) {
                // console.error(error)
            }
        });
    }
    ngOnInit() {
    }
    onClick(event) {
        const modalElement = document.getElementById('ion-content-search');
        if (modalElement && !modalElement.contains(event.target)) {
            this.closeEventEmit.emit();
        }
    }
    ngOnChanges(changes) {
        if (changes["textResult"].currentValue && changes["textResult"].currentValue.length > 0) {
            const text = changes["textResult"].currentValue;
            this.subjectSearch.next(text);
        }
    }
    getProductsBySearching(name) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let companies = "";
            let categories = "";
            let franquicies = "";
            if (this.qCompanies)
                companies = this.qCompanies.join(",");
            if (this.qCategory)
                categories = this.qCategory.join(",");
            if (this.qCities)
                franquicies = this.qCities.join(",");
            const response = yield this.clubService.getProductsPaginated(franquicies, this.user.id_contrato, this.currentOffset, this.currentLimit, categories, companies, this.qPricing, this.qTypeSearch, name, this.qSracthWin);
            this.productsSearched.push(...response);
            this.allProducstSearched.push(...response);
            this.areLoadingProducts = false;
            this.isEnableLoadProducts = response.length === this.currentLimit;
        });
    }
    loadRouterParamsCat() {
        // this.isSearchingData = true;
        var action;
        this.paramSub = this.route.queryParamMap.subscribe((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { params } = data;
            //Save companyID coming from the route
            this.qCompanies = [];
            this.qCities = [];
            this.qCategory = [];
            if (params.qCategory) {
                this.qCategory = params.qCategory.split('--');
                action = FILTER_ACTIONS.qCategory;
            }
            if (params.qPricing) {
                this.qPricing = params.qPricing.split('--');
                action = FILTER_ACTIONS.qPricing;
            }
            if (params.qDiscountType) {
                action = FILTER_ACTIONS.qTabDiscountType;
            }
            if (params.qCities) {
                this.qCities = params.qCities.split('--');
                action = FILTER_ACTIONS.qCities;
            }
            if (params.qCompanies) {
                this.qCompanies = params.qCompanies.split('--');
                action = FILTER_ACTIONS.qCompanies;
            }
            if (params.qtypePromoo) {
                this.qtypePromoo = params.qtypePromoo;
            }
            if (params.qTypeSearch) {
                this.qTypeSearch = params.qTypeSearch;
            }
            if (params.qSracthWin) {
                this.qSracthWin = Number(params.qSracthWin);
                console.log(this.qSracthWin);
            }
        }));
    }
    updateProductsData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    ngOnDestroy() {
        this.subjectSearch.unsubscribe();
        this.allProducstSearched = [];
        this.productsSearched = [];
    }
}
