import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';
export class ContainerCompaniesComponent {
    constructor(router, companyService) {
        this.router = router;
        this.companyService = companyService;
        this.companies = [];
        this.titleContainer = "";
        //public variables
        this.allCompanies = [];
        this.areLoadingCompanies = false;
        this.areAllCompaniesLoaded = false;
        //private variables
        this.limit = 15;
        this.offset = 1;
        this.onClickButtonTitle = new EventEmitter;
        this.onInfiniteScroll = (event, typeScroll) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            event.stopPropagation();
            const scrollEvent = event.target;
            if (scrollEvent.scrollLeft >= scrollEvent.scrollWidth - scrollEvent.offsetWidth) {
                console.log("Infinite Scroll");
                switch (typeScroll) {
                    case "companies":
                        this.offset++;
                        this.updateCompaniesData();
                        break;
                }
            }
        });
        //* To handle scrolling product slider, (Only available on PC)
        this.handleSliderNav = (elemScroll, direction) => {
            const element = elemScroll.querySelector(".product_slider");
            console.log(element);
            if (element) {
                element.scrollBy({ left: direction === 'right' ? 272 : -272, behavior: 'smooth' });
            }
        };
    }
    ngOnInit() {
        this.areLoadingCompanies = true;
        this.updateCompaniesData();
    }
    goToCompanyProfile(companyId) {
        this.router.navigate(['club-fibex', 'company-profile', companyId], { replaceUrl: false });
    }
    onClickTitleAction() {
        this.onClickButtonTitle.emit();
    }
    onGetMoreCompanies() {
    }
    /**
    * Updates the list of companies by fetching more data from the server.
    * This method is used for pagination, loading additional companies when needed.
    * @returns {Promise<void>} A promise that resolves when the update operation is complete.
    * @remarks
    * This method will only fetch more companies if not all companies have been loaded yet.
    * It updates several class properties://+
    * - Increments the `offset` for pagination//+
    * - Adds newly fetched companies to `allCompanies`//+
    * - Updates `areAllCompaniesLoaded` based on the number of companies fetched
    * - Manages the `areLoadingCompanies` flag to indicate loading state
    * @throws Will throw an error if the company service fails to fetch data.
    */
    updateCompaniesData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.areAllCompaniesLoaded) {
                this.areLoadingCompanies = true; //-
                this.areLoadingCompanies = true; //+
                const getMoreCompanies = yield this.companyService.GetCompaniesPaginated(this.limit, this.offset);
                if (getMoreCompanies.length > 0) {
                    yield new Promise(resolve => setTimeout(resolve, 500));
                    this.allCompanies.push(...getMoreCompanies);
                }
                this.areAllCompaniesLoaded = (getMoreCompanies.length < this.limit);
                this.areAllCompaniesLoaded = (getMoreCompanies.length < this.limit);
                this.areLoadingCompanies = false;
            }
        });
    }
    isDesktop() {
        return window.innerWidth > 768;
    }
}
