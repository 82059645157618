import { Injectable } from "@angular/core";
import axios, { AxiosResponse } from "axios";
import { environment } from '../../environments/environment';
import { LocalStorageService } from "./local-storage.service";
import { ClubFibexFeedingService } from "./club-fibex-feeding.service";

interface responseQRTokens{
    status: number;
    message: string;
    tknlg: string;
    refreshTkn:string;
}

@Injectable({
    providedIn: 'root'
  })
  export class QRService {

    private rootAPI: string = environment.apiQR;
    private rexpresionURL = /^https?:\/\/qr\.fibextelecom\.info\/[^\s\/?#]+(?:\?[^\s#]*)?$/;
    private tokenStorageName = "tknQR";
    constructor(
        private _localStorageService: LocalStorageService,
        private clubServie: ClubFibexFeedingService
    ){}

    public async generateTokens(){
        try {
        const {identidad} = this._localStorageService.get("cedulas")[0];
         const Data: any = {cedula: identidad};
         const responseAPi: any = await axios.post(`${this.rootAPI}/loginAuth`,Data);
         const data: responseQRTokens = responseAPi.data;
          const {tknlg,refreshTkn} = data;
          this._localStorageService.set(this.tokenStorageName,{tknlg,refreshTkn});

        } catch (error) {
            console.log("Error en obtener la respuesta por favor intenta mas tarde");
            console.log(error);
        }
    }
    public async logOutToken(){
        const tokens = this._localStorageService.get(this.tokenStorageName);
        try {
         const headers= {
            refresh: `Bearer ${tokens.refreshTkn}`
         }
         const responseAPi: any = await axios.get(`${this.rootAPI}/logOutToken`,{headers});
         const data: responseQRTokens = responseAPi.data;
         this._localStorageService.remove(this.tokenStorageName);
         
         return Promise.resolve(data);
        } catch (error) {
            console.log("Error en obtener la respuesta por favor intenta mas tarde");
            console.log(error);
            return Promise.reject(error);
        }
    }
    public async redirectCoupon(qrContent: string,codeCupon?: string){
    //EVALUAMOS EL QR 
    try{
    const respEXprReg = this.rexpresionURL.test(qrContent);
    // if(respEXprReg){
        const objectTokens = this._localStorageService.get(this.tokenStorageName);
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "tknlg": `Bearer ${objectTokens.tknlg}`,
            "refresh": `Bearer ${objectTokens.refreshTkn}`
        };
        const responseCoupon:any = await this.clubServie.getCouponByID(codeCupon)
        const urlResponse = responseCoupon.QR ? `${qrContent}?codeQR=${responseCoupon.QR}` : qrContent;

        const responseGet:any = await axios.get(`${urlResponse}`,{headers});
        const dataResponse = responseGet.data;
        //actualizar los tokens
        if(dataResponse.hasOwnProperty("error") && dataResponse.hasOwnProperty("status")  && !dataResponse.status) return Promise.reject(dataResponse);
        else if(dataResponse.hasOwnProperty("error") ) return Promise.reject(dataResponse.error);
        return Promise.resolve({status: true,message: "Se ha realizado correctamente el procedimiento de la lectura del QR",data: dataResponse.data});

   // }
   // return Promise.resolve({status: false,message: "No cumple con la expresion regular del URL"})
    }
    catch(error){
        if(error.hasOwnProperty("code") && error.code===3000){
            const responseData = await this.generateTokens(); 
        }
        return Promise.reject({error})
    }
    }

  }