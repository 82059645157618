import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo, UserInterface } from '../interfaces/userInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';
import { ExtractUrl } from '../lib/extract.url.params';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'apollo-link';
import { CedulaStorage } from './cedula-storage.service';

export interface IUserSae {
  apellido: string,
  c_sistema: boolean,
  calificacion: null,
  campo1: string,
  campo2: string,
  campo3: string,
  campo4: string,
  campo5: string,
  cedula: string,
  cli_id_persona: string,
  cliente: string, // Nombre completo
  cod_id_persona: string,
  codigo_franq: string,
  codigo_legal: string,
  contrato_fisico: string,
  contrato_imp: string,
  costo_dif_men: string,
  direccion_franq: string,
  email: string,
  etiqueta: string,
  etiqueta_n: string | null,
  fecha_actd: string,
  fecha_contrato: string,
  fecha_firma: string | null,
  fecha_nac: string,
  firma_digital: string | null,
  id_contrato: string,
  id_direccion: string,
  id_emp: string,
  id_estrato: string,
  id_franq: string,
  id_g_a: string,
  id_gf: string,
  id_iva: string,
  id_operador: string,
  id_persona: string,
  id_venta: string,
  inicial_doc: string,
  iniciales: string,
  ip: string,
  login_firma: string | null,
  logo_franq: string,
  mac: string,
  nombre: string,
  nombre_franq: string,
  nro_contrato: string,
  nro_contrato_ant: string | null,
  nro_cuenta_pago: string,
  num: number,
  obser_franq: string,
  observacion: string,
  ord: number,
  postel: string,
  pto: string,
  saldo: string,
  serie: string,
  serie_factura: string,
  serie_nro_cp: string | null,
  serie_p: string,
  serie_pago: string,
  sexo: string,
  sinc_integracion: boolean,
  status_contrato: string,
  taps: string | null,
  telefono: string,
  telefono4: string | null,
  telf_adic: string,
  telf_casa: string,
  tipo_abonado: string,
  tipo_cliente: string,
  tipo_fact: string,
  ultima_act: string,
  ultimo_corte: string | null,
  ultimo_pago: string | null,
  url_epayco: string | null,
  y: string | null
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticationService {
  public userState: EventEmitter<IUserSae> = new EventEmitter<IUserSae>()
  constructor(
    public http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _logs: LogErrorsService,
    private _consulta: ConsultasService,
    private security: SeguridadDatos,
    private  cedulaStorage: CedulaStorage
  ) { 
    const params = ExtractUrl.GetParamsThisPage();
    if(params['dni'] && params['usermail'] && params['userphone']){
      this.tokenExtern = params;
       
    }
    else{
      this.app$.next(false)
    }
  }

  /**
   * Variables en el servicio
  */
  private isLogged: boolean = true;
  
  private tokenExtern: any | null;
 
  public app$ = new BehaviorSubject<boolean>(false);
 
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  /**
  * Getters y Setters de las variables
  */
 
  getIsLogged(): boolean{ return this.isLogged}; 

  setIsLogged(value: boolean): void{this.isLogged = value};

  public getUser(): IUserSae | null {
    const userData = this._localStorageService.get('DataToRegister');
    return (userData && userData.DataUserSae) || null;
  }

  public getUsers(): IUserSae[] {
    const userData = this._localStorageService.get('DataToRegister');
    return userData ? userData.DataUser : [];
  }

  public selectUser(nro_contrato: string) {
    const users = this.getUsers();
    const user = users.find(userItem => userItem.nro_contrato === nro_contrato);

    if(user) {
      const userData = this._localStorageService.get("DataToRegister");
      
      this._localStorageService.set("DataToRegister", {
        ...userData,
        DataUserSae: user
      });

      return user;
    }
  }

  public registerUser(userInfo: UserInfo) {
    this.login({ email: userInfo.email, password: userInfo.password, telefono: userInfo.phone })
    return true;
  }


  public noRegisterdUser() {
    const users = this.getUser();
    if (!users) {
      return true;
    }
    return false;
  }

  public login(userInfo: { email: string, password: string, telefono: string }) {
    const registeredUser = this.getUser();
    if (!registeredUser) {
      return false;
    }
    // const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
    const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase());
    const validPhoneUser = (userInfo.telefono === registeredUser.telefono);
    if (!validUser && !validPhoneUser) {
      return false;
    }

    this._localStorageService.set('auth-user', userInfo);
    return true;
  }

  modifyUser(userInfo: {[key in keyof IUserSae]?: IUserSae[key]}) {
    const info = this._localStorageService.get("DataToRegister");
    let userInfoUpdate = info.DataUserSae = { 
      ...info.DataUserSae,
      ...userInfo
    };
    info.DataUser = info.DataUser.map((user: any) => {
      return user.id_contrato === userInfoUpdate.id_contrato ? userInfoUpdate : user
    });
    this._localStorageService.set("DataToRegister", info);
    this.userState.emit(userInfoUpdate);
  }

  public logout() {
    this._localStorageService.remove('auth-user');
  }

  public async loginCRM(email: string, password: string) {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    try {
      const responseCliente: any = await this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
      if (!responseCliente) {
        return { status: false };
      }

      this._localStorageService.set('HaveFibexP', responseCliente.fibex_play)

      return responseCliente;

    } catch (error) {
      this._logs.insertarError(error.message);
      return { status: false };
    }
  }

  public async resetPassword(email: string, password: string) {
    try {
      const response: any = await this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        email: null,
        password: null,
      }
    }
  }
  public async verifyCedula(cedula: string) {
    try {
      const response: any = await this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        cedula: null,
      };
    }
  }
  isAuthenticated(){
    const token = this.externalData();
    if (token) {
      const dataResponse: { dni: string, usermail: string, userphone: string} = token
      
      this.loginAuth(dataResponse).then((user) => {
        
        this.isAuthenticated$.next(true);
        //Guardar un valor en el local storage referente al inicio de sesion de manera externa
        this._localStorageService.set('is-auth-external','true');
        this.app$.next(true);
      }).catch((error) => {
        this.isAuthenticated$.next(false);
      });
    } else {
      this.isAuthenticated$.next(false);
      
    }
   
  }
  public async loginAuth({ dni, usermail, userphone, ask, answer }: { dni: string, usermail: string, userphone: string, ask?: string, answer?: string }){

    
    try {
      const responseConsulta: any = await this._consulta.authUserCredentials({ dni, usermail, userphone, ask, answer });
      const data = responseConsulta.data.map((item: any) => ({ ...item, cliente: (item.nombre + " " + item.apellido).trim() }));
      
      console.table('RES >>>', responseConsulta);
  

      //GENERAMOS TODOS LOS STORAGE DENTRO DEL LOCAL STORAGE
      this._localStorageService.set("DataToRegister", {
        DataUser: data[0],
        DataUserSae: data[0],
        Email: data[0].email
      });
  
      this._localStorageService.set("auth", responseConsulta.token);
      
      const userInfo = {
        email: data[0].email.toLowerCase(),
        identidad: data.cedula || data[0].cedula,
        tipoCliente:
            `${data.tipo_abonado} ${data.tipo_cliente}` ||
            "CLIENTE NATURAL",
        fullName: data[0]
            ? data[0].cliente
            : `${data[0].nombre.toLowerCase()} ${data.apellido.toLowerCase()}`,
        phone: data[0].telefono,
        password: data[0].cedula || "",
        id_franq: data[0].id_franq,
        nro_contrato: data[0].nro_contrato,
        status: data.status_contrato,
    };

    const userData = {
        email: data[0].email.toLowerCase(),
        password: data[0].cedula,
        telefono:  data[0].telefono
    };
    this.registerUser(userInfo);
    const value = this.login(userData);
    this.cedulaStorage.setCedula(userInfo, data);
    this.setIsLogged(true);
     return { data, status: 'logged successfully' };
    } catch (error) {
      console.error('Error en loginAuth', error);
      throw error;
    }
  }

  
  externalData(){
    return this.tokenExtern;
  };

  //Consultar los datos del localStorage referentes a la sesion del usuario. Solo indica si esta logueado de manera externa o no.
  isLoggedExternally(): boolean {
    return this._localStorageService.get('is-auth-external');
  }

}